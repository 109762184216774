<template>
    <div>
        <v-container app v-on:keyup.enter="submitlogin">
            <v-row class="d-flex justify-center">
                <v-col cols=8> 
                    <div class="text-center">
                        <img width="50%" class="width:10%" src="../../public/tsalogo.jpg">
                        <h2>RESIDENT SELF-MONITORING PROGRAM</h2>
                    </div>
                </v-col>
            </v-row>
            <div>
                <v-row class="d-flex justify-center mb-n10">
                    <v-col cols=4>  <!-- USER NAME FIELD -->
                            <v-text-field label="Username" v-model="creds.username" outlined></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                        <v-col cols=4><!-- PASSWORD FIELD -->
                            <v-text-field type=password label="Password" outlined v-model="creds.password"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center mt-n7">
                        <v-col cols=4 class="d-flex justify-center">
                            <v-btn :loading="bloading" :disabled="bloading" v-on:keyup.enter="submitlogin" v-on:click="submitlogin">LOGIN</v-btn>
                        </v-col>
                </v-row>
            </div>

            <!-- ERROR SCACK BAR -->
            <v-snackbar
                centered
                style="z-index: 9999"
                v-model="invalidSB"
                :timeout=2000
                >Login Failed
                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="invalidSB = false"
                        >X
                    </v-btn>
                </template>
            </v-snackbar>
        </v-container>
    </div>
</template>


<script>

import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
    name:'login',
    components: {},
    mounted: function(){},
    data: function () {
        return {
            creds:{
                username:'',
                password:''
                },
            invalidSB:false,
            bloading:false,
        }
        },
    methods: {
      ...mapActions({
        attemptLogin: 'attemptLogin',
        }),
      submitlogin(){
        this.bloading=true
        this.attemptLogin(this.creds);
        setTimeout(()=>{                        /* try login,then navigate to the right page/show error after 100ms */
            console.log(this.authenticated)
            console.log(this.admin)
            if(this.authenticated && !(this.admin)){  /* if admin,go to admin npage */
                this.$router.replace({
                    name: 'residenthome'
                    })
                } 
            if ((this.authenticated == true) && (this.admin == true)) {/* if resident, go to survey */
                this.$router.replace({
                    name: 'adminhome'
                })
            }
            if (this.authenticated == false) {
                this.invalidSB = true;this.bloading=false
                }
        },700)        
      },

    },
    computed:{
        ...mapGetters({
            authenticated: 'authenticated',
            admin: 'admin'
            })
    }
}

</script>
