<template>
    <div style="overflow-x:hidden;overflow-y:hidden">
        <v-container app>

            <v-row class="d-flex justify-space-around">

                <v-col cols=1 class="d-flex justify-start">
                     <div style="display:inline-block"> <img width="30%" src="../../public/tsalogo.svg"></div>
                    </v-col>   
                
                <v-col cols=10 class="d-flex justify-center">
                    <div class="">  <div v-if="intro" style="height:300px" class=""> <img class="crayons mb-15" src="../../public/girlontrail.jpg"> </div>  </div>
                    </v-col> 
                
                <v-col style="z-index:1000000" class="d-flex justify-space-around" cols=1> <div class="mr-8"> <v-btn small v-on:click="submitlogout">
                                LOGOUT
                            </v-btn></div></v-col>
            
<!-- ************************************************** TOP PICTURES & NAV ************************************************************************************************ -->


              </v-row>

            <div v-if="intro" id='intro'> 
                        <v-card style="opacity:0.75">
                        <div class="text-center pt-10">   
                            <h2>This Symptom Tracker Belongs To:</h2>
                            <p class="text-h4 mt-2">{{currentuser.name}} </p>

                              <p class="text-h6 mt-10 mb-n1"> This app is to track if you have symptoms of the COVID-19 virus </p>
                          <p class="text-h6"> You will use colours to represent symptoms </p>
                           <p style="text-decoration: underline;font-weight:bold" class="text-h6"> Always let staff know if you have symptoms or temperature of 37.8ᵒC / 100.04ᵒF or over </p>

                        </div>

                        <div>

                            <div class="text-center">

                            <div v-if="(completions.length<1)" class="surveybtnwrap">
                             
                            <img class='surveybtn' :disabled="bloading" @click='startsurvey' width="100%" src="../../public/sun.svg">
                          <div class='surveybtnlabel'>  <span text plain :loading="bloading" @click='startsurvey' >Click to Start Screener #1</span></div>
                        

                            </div></div>


<!-- ************************************************** Start Survey Section ************************************************************************************************ -->


                             <div class="text-center">

                         <div v-if="(completions.length==1)" class="surveybtnwrap mb-10">
                                
                                <v-row class="d-flex justify-center">
                                <v-col class="d-flex justify-center" cols=6>
                            
                            <div class="completedicon text-center">
                             <img class='completedpic ml-n15 mb-10 mt-n15' width="200%" src="../../public/sun.svg">
                           <v-icon class="completedcheck mt-n15" x-large color="green">mdi-check-bold</v-icon>
                            </div></v-col>

                            
                            <v-col class="d-flex justify-center" cols=6> <div class="text-center"> <img class='surveybtn mt-9' @click='startsurvey' width="200%" src="../../public/star.svg">
                            
                            <div class='surveybtnlabel2' style="width:200%">  <span text plain :loading="bloading" @click='startsurvey' >Click to Start Screener #2</span></div>
                            </div>
                            
                            </v-col>

                                </v-row>

                            </div></div>

                        
                         <div class="text-center" v-if="(completions.length>1)">

                           <p v-if="completions.length>1" class="text-h6"> You have completed Both Screeners for the Day! GREAT WORK SELF MONITORING!</p>

                           <v-row>
                            <v-col cols=3><div class="text-center"><img src='../../public/9-finishedleft.svg' width="100%"></div> </v-col>
                            <v-col cols=3><div class="text-center"><img src='../../public/sun.svg' width="100%"></div> </v-col>
                            <v-col cols=3><div class="text-center"><img src='../../public/star.svg' width="100%"></div> </v-col>
                            <v-col cols=3><div class="text-center"><img src='../../public/9-finishedright.svg' width="100%"></div> </v-col>
                           </v-row>





                            </div>


                   <div style="" class="mb-n15"><v-btn @click="showintrocal = !showintrocal" x-large><v-icon x-large>mdi-calendar</v-icon></v-btn></div>


                        </div>
            
             
                        
                        </v-card></div> 
   
                        <div class="" id='introcal' v-if="showintrocal">
                        <div class="text-center text-h4"><p>{{ months[(new Date().getMonth())] }}</p></div>

                          <v-calendar 
                          
                           class="mb-15"
                            ref="calendar"
                            :disabled="true"
                            v-model="value"
                            type="month"
                            :events="events"
                            event-height="40"
                            :event-overlap-threshold="30"
                            event-color="blue"
                        ></v-calendar></div> 


                     
                            
                                  
                            
                          





                        <div class="text-center">
                <v-dialog
                v-model="timemsg"
                width="500"
                >
              
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                    Too Early...
                    </v-card-title>

                    <v-card-text class="mt-5 text-h6">
                    <p>Sorry, you must wait at least 3 hours before you can start your second screener. </p>
                    You may begin at {{ new Date(comebacktime).toLocaleString('en-US',{timezone:"America/New_York",timeStyle:"short",dateStyle:"medium"}).slice(-8) }}
                    </v-card-text>



                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="timemsg = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog></div>


<!-- ************************************************** Survey TOP SECTION ************************************************************************************************ -->

            <div v-if="survey" id='survey'>

                <div class="text-center mt-n8 mb-n2"><span>{{currentuser.name}} - {{currentuser.house}} - {{date}} - {{time}}</span></div>
               

<!-- ************************************************** Survey PAGE 1 ************************************************************************************************ -->

                <v-stepper v-model="question" class="mt-5" >
                    
                    <v-stepper-header>
                     <v-stepper-step :key="`symptomscreen1`" :complete="1<question" step="1"> 
                            1 
                    </v-stepper-step>
                     <v-stepper-step :key="`symptomscreen1`" :complete="2<question" step="2"> 
                            2 
                    </v-stepper-step>
                     <v-stepper-step :key="`symptomscreen3`" :complete="3<question" step="3"> 
                            3
                    </v-stepper-step>
                     <v-stepper-step :key="`symptomscreen4`" :complete="4<question" step="4"> 
                            4
                    </v-stepper-step>
                    <v-stepper-step :key="`symptomscreen4`" :complete="5<question" step="5"> 
                            5
                    </v-stepper-step>
                      <v-stepper-step :key="`symptomscreen5`" :complete="6<question" step="6"> 
                            6
                    </v-stepper-step>


                    </v-stepper-header>


                    <v-stepper-content style="height:50%" :key="`symptoms1-content`" step="1"> 
                        <span  class="text-h6">Do you have any of these symptoms?</span>
                        <div class="symptomwrap text-center mt-5">
                            <v-row class="d-flex justify-center">
                                <v-col cols=6>            
                                    <div class="symptom">
                                        <div class="symptomname text-body-1 text-center mr-10"><span>Fever | Chills</span></div>
                                            <div class="symptomimg mt-1 mb-10"> <img style='transform:scale(1.5)' src="../../public/1-fever.svg"> </div>
                                                <div class="smyptomans">
                                                    <div class="text-center">
                                                        <v-row class="d-flex justify-center"> 
                                                            <v-col cols=6 class="d-flex justify-center">
                                                                <v-btn v-if="fever != 'yes' || fever == '' "  @click="fever='yes'" fab color="green lighten-1" class="symptombtn mr-15 white--text">YES</v-btn>
                                                                <v-btn v-if="fever == 'yes'" @click="fever=''" fab color="green lighten-1" class="symptombtnactive mr-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=6 >
                                                                <v-btn v-if="fever != 'no' || fever == '' "  @click="fever='no'" fab color="red lighten-1" class="symptombtn white--text">NO</v-btn>
                                                                <v-btn v-if="fever == 'no'" @click="fever=''" fab color="red lighten-1" class="symptombtnactive mr-15">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 

                                <v-col cols=6>            
                                    <div class="symptom">
                                        <div class="symptomname text-body-1 text-center" style="width:300px"><span class="ml-n15 mr-10">New/worse cough | difficulty breathing</span></div>
                                            <div class="symptomimg mt-3 mb-10"> <img style='transform:scale(1.3)' src="../../public/2-newcough.svg"> </div>
                                                <div class="smyptomans">
                                                    <div class="text-center">
                                                        <v-row class="d-flex justify-space-around mt-n6 mr-5"> 
                                                            <v-col cols=5 class="ml-n12">
                                                                <v-btn v-if="newcough != 'yes' || newcough == '' "  @click="newcough='yes'" fab color="green lighten-1" class="symptombtn mr-8 white--text">YES</v-btn>
                                                                <v-btn v-if="newcough == 'yes'" @click="newcough=''" fab color="green lighten-1" class="symptombtnactive mr-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=5 class='ml-n15'>
                                                                <v-btn v-if="newcough != 'no' || newcough == '' "  @click="newcough='no'" fab color="red lighten-1" class="symptombtn white--text">NO</v-btn>
                                                                <v-btn v-if="newcough == 'no'" @click="newcough=''" fab color="red lighten-1" class="symptombtnactive mr-15">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 


                            </v-row>

                        <div class="text-right mb-10">
                            <v-btn ref="q1" @click="answerquestion();goto('q2')" class="contbtn" x-large :disabled="((fever == '')||(newcough == ''))" color="blue lighten-2">Continue</v-btn>
                        </div>
                          
                        
                        </div>


                    </v-stepper-content>


<!-- ************************************************** Survey PAGE 2 ************************************************************************************************ -->

                   
                    <v-stepper-content :key="`symptoms1-content`" step="2"> 
                        <span  class="text-h6">Do you have any of these symptoms?</span>
                        <div class="symptomwrap text-center mt-5">
                            <v-row class="d-flex justify-center mr-10">
                                <v-col cols=5 class="d-flex justify-center ml-n5" >            
                                    <div  class="symptom">
                                        <div class="symptomname text-body-1">Sore throat | trouble swallowing</div>
                                            <div class="symptomimg mt-3 mb-10 ml-2"> <img style='transform:scale(1.5)' src="../../public/3-sorethroat.svg"> </div>
                                                <div class="smyptomans">
                                                    <div class="text-center">

                                                        <v-row class=""> 
                                                            <v-col cols=6>
                                                                <v-btn v-if="sorethroat != 'yes' || sorethroat == '' "  @click="sorethroat='yes'" fab color="green lighten-1" class="symptombtn mr-15 white--text">YES</v-btn>
                                                                <v-btn v-if="sorethroat == 'yes'" @click="sorethroat=''" fab color="green lighten-1" class="symptombtnactive mr-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=6>
                                                                <v-btn v-if="sorethroat != 'no' || sorethroat == '' "  @click="sorethroat='no'" fab color="red lighten-1" class="symptombtn ml-10 white--text">NO</v-btn>
                                                                <v-btn v-if="sorethroat == 'no'" @click="sorethroat=''" fab color="red lighten-1" class="ml-15 symptombtnactive mr-15">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 


                                <v-col cols=6 class="d-flex justify-center mr-n15">            
                                    <div class="symptom mr-5">
                                        <div class="symptomname text-body-1 text-center"><span>Runny nose | stuffy nose</span><br>
                                        <span>change in taste or smell</span></div>


                                        
                                            <div class="mt-10" style="width:320px;height:300px"> <img style='transform:scale(2)' id="runnynose" src="../../public/4-runnynosefull.svg">
                                             
                                             
                                             </div>
                                             
                                             
                                             
                                                <div class="smyptomans mb-n15" id="runnynoseans">
                                                    <div class="text-center">
                                                        <v-row> 
                                                            <v-col cols=6>
                                                                <v-btn v-if="runnynose != 'yes' || runnynose == '' "  @click="runnynose='yes'" fab color="green lighten-1" class="symptombtn mr-15 white--text">YES</v-btn>
                                                                <v-btn v-if="runnynose == 'yes'" @click="runnynose=''" fab color="green lighten-1" class="symptombtnactive mb-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=4>
                                                                <v-btn v-if="runnynose != 'no' || runnynose == '' "  @click="runnynose='no'" fab color="red lighten-1" class="symptombtn white--text">NO</v-btn>
                                                                <v-btn v-if="runnynose == 'no'" @click="runnynose=''" fab color="red lighten-1" class="symptombtnactive mr-15">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 


                            </v-row>

                        <div class="text-right mb-10 mt-n15">
                            <v-btn ref="q2" @click="answerquestion();goto('q3')" class="contbtn" x-large :disabled="((runnynose == '')||(sorethroat == ''))" color="blue lighten-2">Continue</v-btn>
                        </div>

                        <div class="text-left ml-10">
                        <v-btn @click="previousquestion();goto('q1')" text outlined color="grey" class="mb-3 mr-3 contbtn"> PREVIOUS</v-btn>
                        </div>
                           

                        </div>


                    </v-stepper-content>



<!-- ************************************************** Survey PAGE 3 ************************************************************************************************ -->



    
                    <v-stepper-content :key="`symptoms3-content`" step="3"> 
                        <span ref="q3" class="text-h6">Do you have any of these symptoms?</span>
                        <div class="symptomwrap text-center mt-5 mr-15 ml-n15">
                            <v-row class="d-flex justify-start">
                                <v-col cols=6 class="d-flex justify-start">            
                                    <div  class="symptom text-center">

                                        <div class="symptomname text-body-1">Nausea | diarrhea | vomiting | abdominal pain</div>
                                            <div class="symptomimg ml-15 mt-15 mb-10"> <img width="100%" style='transform:scale(1.5)' src="../../public/5-abpainfull.svg"> </div>
                                                <div ref="q3" class="smyptomans">
                                                    <div class="text-center">
                                                        <v-row class='mt-15'> 
                                                            <v-col cols=5 class="ml-15">
                                                                <v-btn v-if="nausea != 'yes' || nausea == '' "  @click="nausea='yes'" fab color="green lighten-1" class="symptombtn mr-8 white--text">YES</v-btn>
                                                                <v-btn v-if="nausea == 'yes'" @click="nausea=''" fab color="green lighten-1" class="symptombtnactive mr-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=2>
                                                                <v-btn v-if="nausea != 'no' || nausea == '' "  @click="nausea='no'" fab color="red lighten-1" class="symptombtn white--text ml-4">NO</v-btn>
                                                                <v-btn v-if="nausea == 'no'" @click="nausea=''" fab color="red lighten-1" class="symptombtnactive ml-6 mr-15">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 

                                <v-col cols=6>            
                                    <div class="symptom mr-12">
                                        <div class="symptomname text-body-1 mb-15">Tired | muscle pain | headache | change in function/health</div>
                                            <div class="symptomimg mb-15"> <img class="mt-15 ml-15" width="100%" style="transform:scale(2.2)" src="../../public/6-headachefull.svg">
                                             
                                             </div>
                                                <div class="smyptomans ml-15">
                                                    <div class="text-center ">
                                                        <v-row class="d-flex mt-10 mb-10"> 
                                                            <v-col cols=5>
                                                                <v-btn v-if="tired != 'yes' || tired == '' "  @click="tired='yes'" fab color="green lighten-1" class="symptombtn mr-15 white--text">YES</v-btn>
                                                                <v-btn v-if="tired == 'yes'" @click="tired=''" fab color="green lighten-1" class="symptombtnactive ml-n8 mr-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=1 class="ml-2">
                                                                <v-btn v-if="tired != 'no' || tired == '' "  @click="tired='no'" fab color="red lighten-1" class="symptombtn white--text ml-7">NO</v-btn>
                                                                <v-btn v-if="tired == 'no'" @click="tired=''" fab color="red lighten-1" class="symptombtnactive mr-15 ml-13">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 


                            </v-row>

                        <div class="text-right mb-10 mt-n10">
                            <v-btn ref="q3" @click="answerquestion()" class="contbtn" x-large :disabled="((nausea == '')||(tired == ''))" color="blue lighten-2">Continue</v-btn>
                        </div>

                        <div class="text-left ml-15">
                        <v-btn @click="previousquestion" text outlined color="grey" class="mb-3 ml-10 contbtn"> PREVIOUS</v-btn>
                        </div>
                           
                        </div>


                    </v-stepper-content>



<!-- ************************************************** Survey PAGE 4 ************************************************************************************************ -->



    
                    <v-stepper-content :key="`symptoms4-content`" step="4"> 
                        <span ref="q4" class="text-h6">Do you have any of these symptoms?</span>
                        <div class="symptomwrap text-center mt-5 mr-15 ml-n15">
                            <v-row class="d-flex justify-space-between">
                                <v-col cols=6 class="d-flex justify-start">            
                                    <div class="symptom">
                                        <div class="symptomname text-body-1 ml-15">New falls | pink eye</div>
                                            <div class="symptomimg ml-15 mt-15"> <img width="100%" style="transform:scale(1.8)" src="../../public/7-newfallsfull.svg"> </div>
                                                <div class="smyptomans ml-8">
                                                    <div class="text-center">
                                                        <v-row> 
                                                            <v-col cols=6 class="ml-15">
                                                                <v-btn v-if="newfalls != 'yes' || newfalls == '' "  @click="newfalls='yes'" fab color="green lighten-1" class="symptombtn mr-15 white--text">YES</v-btn>
                                                                <v-btn v-if="newfalls == 'yes'" @click="newfalls=''" fab color="green lighten-1" class="symptombtnactive mr-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=2>
                                                                <v-btn v-if="newfalls != 'no' || newfalls == '' "  @click="newfalls='no'" fab color="red lighten-1" class="symptombtn white--text ml-2">NO</v-btn>
                                                                <v-btn v-if="newfalls == 'no'" @click="newfalls=''" fab color="red lighten-1" class="symptombtnactive mr-15">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 

                                <v-col cols=6 class="d-flex justify-space-between mr-n10">            
                                    <div class="symptom">
                                        <div class="symptomname text-body-1 mb-5">I feel good</div>
                                            <div class="symptomimg mb-10"> <img width="100%" style="transform:scale(1.5)" src="../../public/8-good.svg">
                                             
                                             <img class="mb-6" v-if="question==3" width="33%" src="../../public/brain.png">
                                            
                                             
                                             </div>
                                                <div class="smyptomans mt-5">
                                                    <div class="text-center">
                                                        <v-row> 
                                                            <v-col cols=6>
                                                                <v-btn v-if="nosymp != 'yes' || nosymp == '' "  @click="nosymp='yes'" fab color="green lighten-1" class="symptombtn mr-15 white--text">YES</v-btn>
                                                                <v-btn v-if="nosymp == 'yes'" @click="nosymp=''" fab color="green lighten-1" class="symptombtnactive mr-15">YES</v-btn>
                                                            </v-col>

                                                            <v-col cols=6>
                                                                <v-btn v-if="nosymp != 'no' || nosymp == '' "  @click="nosymp='no'" fab color="red lighten-1" class="symptombtn white--text">NO</v-btn>
                                                                <v-btn v-if="nosymp == 'no'" @click="nosymp=''" fab color="red lighten-1" class="symptombtnactive ml-3 mr-15">NO</v-btn>
                                                            </v-col>

                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div> 
                                    </v-col> 


                            </v-row>

                        <div class="text-right mb-10 mt-n10">
                            <v-btn @click="answerquestion()" class="contbtn" x-large :disabled="((newfalls == '')||(nosymp == ''))" color="blue lighten-2">Continue</v-btn>
                        </div>

                        <div class="text-left ml-15">
                        <v-btn @click="previousquestion" text outlined color="grey" class="mb-3 ml-10 contbtn"> PREVIOUS</v-btn>
                        </div>
                           
                        </div>


                    </v-stepper-content>


<!-- ************************************************** Survey PAGE 5 ************************************************************************************************ -->



     
                    <v-stepper-content :key="`symptoms5-content`" step="5">

                       <div> <span ref="q5" class="text-h6">Temperature Check</span>
                       <p>Check your temperature and enter the result:</p>
                       
                       </div>
                       

           
                               

                               <v-row>
                                   <v-col cols=2 class="d-flex justify-center">
                                <div id="tempcheckercont" class="text-center" style="z-index:-1;width:80%;display:inline-block"> </div>
                                    <div id="tempchecker">  <img  width="300%" src="../../public/thermometer.png"> </div></v-col>
                                
                                <v-col cols=12 class="d-flex justify-center">
                                <div class="pt-10 mb-10" style=
                                "background-color:rgb(223, 223, 223);height:180px;width:60%;border-left:solid rgb(54,129,191) 50px;border-right:solid rgb(54,129,191) 50px;
                                border-top-left-radius:50px;border-bottom-left-radius:50px;">
                               
                               <v-row class="d-flex justify-center"> <v-col cols=12 class="mb-2 d-flex justify-center">
                               <div class="mt-n5"><v-btn class="mr-4" @click="temp('up')" id="thermomupbtn" fab color="red"><v-icon large>mdi-arrow-up</v-icon></v-btn>
                               <v-btn @click="temp('down')" id="thermomdownbtn" fab color="red"><v-icon large>mdi-arrow-down</v-icon></v-btn></div></v-col>

                               <v-col class="d-flex justify-center" cols=12>  <div id="thermomtxt" class="grey text-center"> <span class="text-h3">{{temperature}}°C</span> </div></v-col></v-row>

                                </div> 

                                </v-col>
                               </v-row>
                                                         
                        <div> 
                        

                        <div class="text-right mb-10">
                            <v-btn @click="answerquestion()" class="contbtn" x-large color="blue lighten-2">Continue</v-btn>
                        </div>

                        <div class="text-left ml-15">
                        <v-btn @click="previousquestion" text outlined color="grey" class="mb-3 ml-10 contbtn"> PREVIOUS</v-btn>
                        </div>
                           
                        </div>


                    </v-stepper-content>

<!-- ************************************************** Survey PAGE 6 ************************************************************************************************ -->


               
                    <v-stepper-content :key="`symptoms6-content`" step="6">

                       <div style="z-index:2"> <span ref="q6"  class="text-h6">SP%02 and Pulse</span>
                       <p>Check your SP%02 and Pulse and enter the result:</p>
                       
                       </div>
                       
                            <v-row><v-col cols=4>  <div class="mt-15 text-center mb-n15">  <img  width="150%" src="../../public/oximeter.png"> </div>
                                     </v-col>
           
                              
                               <v-row class="d-flex justify-center">
                                   <v-col cols=5 class="d-flex justify-center">
                                      
                                <div id="tempcheckercont" class="text-center" style="z-index:-1;width:80%;display:inline-block"> </div>
                                   </v-col>
                                
                                <v-col cols=12 class="d-flex justify-center">
                                <div class="pt-5 mb-10 mt-n15" style=
                                "background-color:rgb(72,81,96);height:360px;width:50%;border:solid rgb(54,129,191) 50px;
                                border-radius:50px">
                               
                               <v-row class="d-flex justify-center">

                               <v-col class="d-flex justify-center" cols=12>  <div id="oxitxt" class="text-center"> <span class="text-h6 white--text ml-n15">SP%02:</span><div style="width:75%;overflowX:hidden;border-bottom:solid 2px white;box-shadow:none"><input maxlength="2" v-model="sp02" class="measuretext text-h4 white--text"></div> </div></v-col>
                               <v-col class="d-flex justify-center" cols=12>  <div id="oxitxt" class="text-center">  <span class="text-h6 white--text ml-n15">Pulse:</span> <div style="width:75%;overflowX:hidden;border-bottom:solid 2px white;box-shadow:none"><input maxlength="3" v-model="pulse" class="measuretext text-h4 white--text"></div> </div></v-col>
                               
                               </v-row>

                                </div> 

                                </v-col></v-row>
                               </v-row>
                                                         
                        <div> 
                        

                        <div class="text-right mb-10">
                            <v-btn @click="complete();window.scrollTo(0, 0)" class="contbtn" x-large color="green lighten-2">FINISH!</v-btn>
                        </div>

                        <div class="text-left ml-15">
                        <v-btn @click="previousquestion" text outlined color="grey" class="mb-3 ml-10 contbtn"> PREVIOUS</v-btn>
                        </div>
                           
                        </div>


                    </v-stepper-content>









                  <!--   <template v-for="i in currentquestionaire.length">
                        <v-stepper-step :key="`${i}-step`" :complete="i<question" :step="i"> 
                            Question {{i}}
                        </v-stepper-step>
                        <v-stepper-content :key="`${i}-content`" :step="i">
                            <span class="text-h3">{{currentquestionaire[i-1].question}}</span>
                            <v-row class="ml-3 mt-8 mb-5">     
                                <v-radio-group  v-model="currentquestionaire[i-1].answer">
                                    <v-radio class="mb-5"
                                        v-for="n in answers"
                                        :key="n"
                                        :label="`${n}`"
                                        :value="n"
                                    ></v-radio>
                                </v-radio-group></v-row>
                  <v-btn @click="previousquestion" v-if="((1<i)&&(i<=(currentquestionaire.length)))" text outlined color="grey" class="mb-3 mr-3"> PREVIOUS   </v-btn>


                <v-btn :disabled="(currentquestionaire[i-1].answer == null)"
                @click="answerquestion()"  v-if="i<(currentquestionaire.length)" large color="blue lighten-3" class="mb-3"> NEXT   </v-btn>
                
                

                <v-btn  @click="complete" v-if="i==(currentquestionaire.length)" x-large color="green lighten-3" class="mb-3"> FINISH!   </v-btn>




                </v-stepper-content>



                </template>
 -->
            



            </v-stepper>


            </div>




            <div  v-if="done" id='done' style="z-index:500;">

                <v-card style="opacity:8">

                      
                    
                        <div style="z-index:50" class="text-center pt-10">   
                            <div>
                <v-row >   
                    <v-col cols=6><span class="text-h4 mt-5">
                        Name: {{currentuser.name}} 
                    </span></v-col>

                   <v-col cols=6> <span class="text-h4 mt-5">
                        Date: {{date}} 
                    </span></v-col>
                  <v-col cols=6>   <span class="text-h4 mt-5">
                        House: {{currentuser.house}} 
                    </span></v-col>
                <v-col cols=6>    <span class="text-h4 mt-5">
                        Time: {{time}} 
                    </span></v-col>
                </v-row></div>


                        </div>

                        <div class="text-center mt-10">


                            <p class="text-h6">GREAT WORK SELF MONITORING!</p>



                            <div class="text-center">

                            <div v-if="(completions.length==1)">
                             
                           <div class="mb-5" >  <span class="text-h6">Screener #1 Complete</span> </div>
                             
                                
                            <div class="text-left">   <img width="40%" class="" src="../../public/9-finishedleft.svg"> 
                               

                                 
                                <img width="15%" src="../../public/sun.svg"> 
                                

                                
                                <img width="40%" class='' src="../../public/9-finishedright.svg"> 
                            </div>    

                          
                        

                            </div></div>






                              <div class="text-center">

                            <div v-if="(completions.length>1)">
                             
                           <div class="mb-5" >  <span class="text-h6">Screener #2 Complete</span> </div>
                             
                                
                            <div class="text-left">   <img width="40%" class="" src="../../public/9-finishedleft.svg"> 
                               

                                 
                                <img width="15%" src="../../public/star.svg"> 
                                

                                
                                <img width="40%" class='' src="../../public/9-finishedright.svg"> 
                            </div>    

                          
                        

                            </div></div>




                        </div>

                        <div class=" mt-15 text-center text-h4"><p>{{ months[(new Date().getMonth())] }}</p></div>
                        
                        <v-calendar class="mt-5"
                            ref="calendar"
                            :disabled="true"
                            v-model="value"
                            type="month"
                            :events="events"
                            event-height="40"
                            :event-overlap-threshold="30"
                            event-color="blue"
                        ></v-calendar>
                        
                        </v-card>


                        
                    




            

                        
            

 


            </div>


        <v-snackbar
                centered
                style="z-index: 9999"
                v-model="invalidSB"
                :timeout=2000
                > Login Failed
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="invalidSB = false"
                    >
                    Close
                    </v-btn>
                </template>
    </v-snackbar>
        </v-container>
    </div>
</template>


<script>


import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import axios from 'axios'


export default {
    name:'residenthome',
    components: {},
    mounted: function(){
        axios.get('/users/info/'+this.user,{
            headers: {"Authorization": "Bearer "+this.token}}).then(
                 (resp) => {this.currentuser=resp.data[0];       
                 
             axios.get('/questionaire/completed/'+this.currentuser.userid,{
            headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => {
                this.completions=resp.data;console.log(this.completions)
                this.timeofirst = this.completions[0].initialized
                var firstinit = new Date(this.timeofirst)
                console.log(`first init -- ${firstinit}`)
                this.comebacktime = firstinit.setHours(firstinit.getHours()+3)
                console.log(`comebacktime -- ${this.comebacktime}`)
                this.combackstr = new Date(this.comebacktime)
                console.log(`comeback str -- ${this.combackstr}`)
                var now = new Date()
                if (now < this.comebacktime){this.threehours = false}
                else{this.threehours = true}
        
            });
            
            setTimeout(()=>{this.setcalendar()},300)





                 }

            
            //get the month and day
            //for every day of the month untill today
                    //get the completions for user
                    //add them to an event
                    //to to the events list





            ).catch( () => {console.log('reauth');this.$router.push('/') } )
       
    },
    data: function () {
        return {
            currentuser:[],
            currentquestionaire:[],
            newanswers:{},
            answers:['yes','no'],
            timeofirst:'',
            answer:'',
            question:1,
            yes:false,
            showintrocal:false,
            value:'',
            fever:"",
            newcough:"",
            sorethroat:"",
            events:[],
            months:["January", "February", "March", "April", "May", "June",
                     "July", "August", "September", "October", "November", "December"],
            runnynose:"",
            nausea:"",
            threehours:true,
            timemsg:false,
            comebacktime:new Date(),
            comebackstr:'',
            tired:"",
            newfalls:"",
            nosymp:"",
            temperature:"--.-",
            sp02:'',
            pulse:"",
            completions:[],
            bloading:false,
            no:false,
            done:false,
            invalidSB:false,
            intro:true,
            survey:false,
        
        }
        },
    methods: {
      ...mapActions({

        logout: 'logout',

      }),

      submitlogout () {
          try{this.logout().then(() => {

          if(!this.authenticated){
          this.$router.replace({
            name: 'login'
          })} else {console.log(+this.authenticated)
              console.log('logout failed')}

          })}catch (e) {console.log('logout failed outside')}
    
    
      },
      startsurvey() {
          this.bloading=true
          console.log(this.user)
          if(this.threehours) {setTimeout( ()=> { axios.get('/questionaire/q/'+this.user,{
          headers:{"Authorization": "Bearer "+this.token}}).then( (resp) => {

              console.log(resp)
              this.currentquestionaire = resp.data
              this.intro = false;
              this.survey = true;
              this.bloading=false
              this.goto('q1')
          
          } ).catch(this.bloading=false)},500  )}
          else {this.timemsg =true;this.bloading=false}
          
         
      },
      async setcalendar(){

        console.log(`setting calendar`)
        var date = new Date().toISOString().slice(0, 10)
        var first = new Date().setDate(1)
        var historydate = new Date(first)
        historydate.setHours(0,0,0,0)
        console.log(historydate)
        var newd = historydate.getDate()
        console.log(newd)
        date = historydate.toISOString().slice(0, 10)
        console.log(`string for initial calendar function - ${date}`)
        while((date != new Date().toISOString().slice(0, 10)))
            {
                    await this.iterate(date)
                    //  newd --;
                    //console.log(`NEW DATE NUMBER: ${newd}`)
                    historydate.setHours(24)
                    console.log(historydate)
                    date=historydate.toISOString().slice(0, 10)
            }

        if (historydate.toISOString().slice(0, 10) == date) {//the final day
                    await this.iterate(date)

        }
        this.historypicker=false
        this.historyday=""
        


        //get the first day of the month
        //get today
            //while first != today
                    //get the surveys for the day for user
                    //add the events and missing events
                    //increment first
            






    },

        iterate(ihistorydate) {axios.get('/questionaire/day/'+ihistorydate,{headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => {

                //add to history
                //increment the date
                console.log(`iterating ${ihistorydate}`)
                  let today = new Date()
                    
                    let r = resp.data;
                    let ru = []
                    for (let j=0;j<r.length;j++) //for every result
                    {
                        if((r[j].userid == this.currentuser.userid)){r[j].date=r[j].completion.slice(0,10);r[j].time=r[j].completion.slice(11,16); ru.push(r[j])} //get the users results into the list
                        else{''}

                    }

                   
                    if ( ((ru.length==0) && (ihistorydate==today.toISOString().slice(0, 10) ))) {this.events.push({"name":"","start":ihistorydate,"end":ihistorydate,
                    "color":'orange'})}
                    if ( ((ru.length==0) && (ihistorydate!=today.toISOString().slice(0, 10) ))) {this.events.push({"name":"❌","start":ihistorydate,"end":ihistorydate,
                    "color":'white'})}

                    if (ru.length==1){//if there is one result
                        console.log(`date has one completion`)
                        if (ihistorydate==today.toISOString().slice(0, 10)) {    
                            
                            console.log(`completion is not late`);
                            console.log(`adding questionaire plus afternoon message`)
                            this.events.push({"name":"   🌞","start":ihistorydate,"end":ihistorydate,
                            "color":'orange',"timed":false})
                            this.events.push({"name":"","start":ihistorydate,"end":ihistorydate,
                            "color":'blue',"timed":!false})
                        
                        
                        }
                        
                        else {
                             this.events.push({"name":"    🌞","start":ihistorydate,"end":ihistorydate,
                            "color":'orange',"timed":!false})
                            this.events.push({"name":"❌","start":ihistorydate,"end":ihistorydate,
                            "color":'grey darken-1',"timed":!false})
                            
                            
                        }
                        
                        } 


                    else if (ru.length==2){
                        console.log(`date has two completions`)
                        if (( (ru[0].ontime == 'true') || (ru[0].ontime == null)  ) && ((ru[1].ontime==null) || (ru[1].ontime == 'true'))    ) {

                            this.events.push({"name":"    🌞","start":ihistorydate,"end":ihistorydate,
                            "color":'orange',"timed":false})
                            this.events.push({"name":"    🌟","start":ihistorydate,"end":ihistorydate,
                            "color":'blue',"timed":false})

                        }

                        if ((ru[0].ontime == 'early') || (ru[1].ontime == 'early') ) {

                            this.events.push({"name":"    🌞","start":ihistorydate,"end":ihistorydate,
                            "color":'orange',"timed":false})
                            this.events.push({"name":"    🌟","start":ihistorydate,"end":ihistorydate,
                            "color":'blue',"timed":false})
                            //this.history.push({"date":historydate.toISOString().slice(0, 10),"time":"EARLY Afternoon Screener","flagged":""})

                        }

                        if ((ru[0].ontime == 'late') || (ru[1].ontime == 'late') ) {
                            
                            this.events.push({"name":"    🌞","start":ihistorydate,"end":ihistorydate,
                            "color":'orange',"timed":false})
                            this.events.push({"name":"    🌟","start":ihistorydate,"end":ihistorydate,
                            "color":'blue',"timed":false})
                            //this.history.push({"date":historydate.toISOString().slice(0, 10),"time":"LATE Morning Screener","flagged":""})

                        }
                        

                    }
                 

                
            } );return true   },

      answerquestion(){
          

          this.question +=1
      },
      previousquestion(){
          this.question-=1
      },
      complete(){

          this.currentquestionaire[0].answer = this.fever
          this.currentquestionaire[1].answer = this.newcough
          this.currentquestionaire[2].answer = this.sorethroat
          this.currentquestionaire[3].answer = this.runnynose
          this.currentquestionaire[4].answer = this.nausea
          this.currentquestionaire[5].answer = this.tired
          this.currentquestionaire[6].answer = this.newfalls
          this.currentquestionaire[7].answer = this.nosymp
          this.currentquestionaire[8].answer = this.temperature
          this.currentquestionaire[9].answer = this.sp02
          this.currentquestionaire[10].answer = this.pulse

          console.log(this.sp02)
          console.log(this.pulse)

          axios.post('/questionaire/completed',this.currentquestionaire,{
               headers:{"Authorization": "Bearer "+this.token}
          }).then(  ()  => {

            axios.get('/questionaire/completed/'+this.currentuser.userid,{
            headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => {
                this.completions=resp.data;  this.survey=false;
              this.done=true; this.events=[]; this.setcalendar()
            })
            
          }  )
      },

      temp(dir){

          if (this.temperature != "--.-") {
              if (dir == 'up'){ 
                  this.temperature = (+this.temperature + 0.10).toFixed(1)
                     }

              if (dir == "down") {
                  this.temperature = (this.temperature - 0.10).toFixed(1)
                  }
        }
          else{this.temperature=36.1}

        
      },
      goto(refName) {
          
      var element = this.$refs[refName];

      element.scrollIntoView(false);

    }



    },


    computed:{
        ...mapGetters({
            authenticated: 'authenticated',
            token:'token',
            user:'user',
        }),

        time(){
            return new Date().toLocaleTimeString('en-US',{timezone:"America/New_York",hour: '2-digit', minute: '2-digit'})
        },

        date(){
            return new Date().toLocaleString('en-US',{timezone:"America/New_York",year: 'numeric', month: 'long', day: 'numeric'})
        },




    }

    }





</script>

<style>

.crayoncont {
    display: inline-block;
    position: relative;
}

.crayons {
    z-index: 100;
    transform: scale(0.16) translateY(-255%);
    opacity: 0.5;
    
}

#intro {
    transform: translateY(-43%);
    z-index: 200;
    overflow-y:visible ;
}

#done { transform: translateY(2%);
        margin-bottom: 100px;}

#survey {
    transform: translateY(-27%);
}

.handpic {
    display: inline-block;
    position: absolute;
    top:-10%;
    right:0%;
    overflow-y:hidden;
    overflow-x:hidden;
}

.thermometer {
    display: inline-block;
    position: absolute;
    top:14%;
    left:-4%;
}

.introimg {

    opacity: 0.4;
}


.surveybtnlabel {

    position: relative;
    font-weight:bold;
    opacity: 1;

}

.surveybtnlabel2 {

    position: relative;
    font-weight:bold;
    opacity: 1;

}



.surveybtnwrap {
    display: inline-block;
    width:15vw;
    margin:auto;

}
.surveybtnwrap p {
    position:relative;
    width:100%;
    transition: 600ms;

}

.surveybtnwrap img {
    transition: 600ms;
    opacity: 0.8;

}

.surveybtnwrap:hover .surveybtnlabel{
    opacity:1;
    cursor: pointer;
}

.surveybtnwrap:hover .surveybtnlabel2{
    opacity:1;
    cursor: pointer;
}


.surveybtnwrap:hover .surveybtn {
    transform: scale(1.3);
    opacity:0.7;
    cursor: pointer;
}

.completedcheck {
    margin-top:-650%;
    margin-right:50%;
    
}

.completedicon {
    position: relative;
    margin-top:140%;
    right:120%
}

#survey {
    transform:translateY(-1%)
}

#label2 {
position: relative;
    margin-top:70%;
    right:160%;
}

.symptom{
    display: inline-block;
    margin-left: 20%;
    margin-right:5%;
}

.symptomimg{
height:200px;
width:200px;
overflow:visible


}

.symptombtn {
    transform: scale(2);
    margin-bottom: 75%;
    transition: 300ms;
    z-index: 1;
}

.symptombtnactive {
    
    transform: scale(3);
    transition: 300ms;
    z-index: 2;

}
.contbtn {
    transform: scale(1.5);
    margin-right: 10%;
}

.surveyheader {

    margin-left:10%;
    margin-right:1%;
    margin-top:30%
}

#runnynose {
    transform:scale(0.8);
    margin-left:25px;
}

#crossout2{
    transform:scale(0.5) translateY(-350%) translateX(85%)
}

#nose {
    transform: scale(0.65) translateY(-890%) translateX(200%)


}

#lips {
    transform: scale(2) translateY(-900%) translateX(50%)


}

#downarrow {
    position: absolute;
    transform: scale(0.3);
    right:16%;
    top:34%;
}

#runnynose {

    transform: scale(0.5) translateY(-28%)
}

#runnynoseans {

    transform: translateX(17%) translateY(-46%)
}

#tempchecker {
    position: relative;

}

#tempcheckercont {
    height:10vh;    
}



#thermomtxt {
    border:solid rgb(202, 202, 202);
    border-radius: 15px;
    width:170px;
 
}

#oxitxt {

    width:100px;
    height: fit-content;

}

.measuretext:focus{outline:none}

.pl-1{
    font-size:20pt;
    margin-top:10px;
    margin-left:30%
}


#introcal {

    top:65%;
    width:100%;
    height:auto;
    overflow-y: hidden;
    margin-top: -17%;

}

.opencal {

overflow-x:hidden;
overflow-y:hidden;
height:145vh

}

.closedcal {
overflow-x:hidden;
overflow-y:hidden

}

</style>