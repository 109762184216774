<template>
    <div>
        <navbar></navbar>

            <v-row class= "d-flex justify-center">
                <v-col cols=9>
            <div>
                <v-card class="mt-5 pt-5">

                    <div class="text-center mb-n10"><span class="text-h5">T2 Administrators</span></div>

                     <v-spacer></v-spacer>
                        <div class="text-right mr-5">  <v-btn v-on:click="newuserform=true" fab><v-icon class="d-flex justify-end">mdi-plus</v-icon></v-btn> </div>

                     <v-data-table
                                :headers="t2headers"
                                hide-default-footer
                                :items="users"
                                
                                :items-per-page="20"
                                class="elevation-1 mb-15">
                                <template v-slot:item.actions="{ item }">
                                      <v-icon
                                            small
                                            class="mr-6 mt-4 mb-4"
                                            v-if="(item.Role =='Resident')"
                                            @click="viewUserhistory(item,7)"
                                              >
                                            mdi-clipboard-plus-outline
                                        </v-icon>
                                        <v-icon
                                            small
                                            class="mr-6 mt-4 mb-4"
                                            @click="openeditUser(item)"
                                            >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            class="mr-6 mt-4 mb-4"
                                            @click="opendeleteUser(item)"
                                            >
                                            mdi-delete
                                        </v-icon>
                                 
                                 
                                 
                                 </template>



                            </v-data-table>
                         <!--********************************** DATA TABLE HEADER  ***************************************** -->

                            <v-row class="d-flex justify-center"><v-col cols=9>

                                 <div class="text-center text-h5"><span>House Users</span></div>


                            <div class="text-center mb-5">
                             <v-select
                            v-model="chosenhouse"
                            :hint="`Select House`"
                            :items="houses"
                            item-text="item"
                            item-value="item"
                            label="House"
                            persistent-hint
                            single-line
                            :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                                            }"
                            >
                             <template v-slot:prepend-item>
                        
                         <v-list-item
                             @click="chosenhouse = ''"
                            ><span>All Users</span></v-list-item> </template>


                            </v-select></div></v-col></v-row>

                          
                                   
                    
           
                        

                        
                         <!--********************************** DATA TABLE ***************************************** -->


                        <v-data-table
                                :headers="headers"
                                :items="users"
                                :search="chosenhouse"
                                :items-per-page="20"
                                class="elevation-1">
                                <template v-slot:item.actions="{ item }">
                                      <v-icon
                                            small
                                            class="mr-6 mt-4 mb-4"
                                            v-if="(item.Role =='Resident')"
                                            @click="viewUserhistory(item,7)"
                                              >
                                            mdi-clipboard-plus-outline
                                        </v-icon>
                                        <v-icon
                                            small
                                            class="mr-6 mt-4 mb-4"
                                            @click="openeditUser(item)"
                                            >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            class="mr-6 mt-4 mb-4"
                                            @click="opendeleteUser(item)"
                                            >
                                            mdi-delete
                                        </v-icon>
                                 
                                 
                                 
                                 </template>



                            </v-data-table>
                    </v-card>

  <!--************************************************ Dialogs ************************************************ -->

                  <!--***************************** New User ******************************* -->
            <v-dialog
                v-model="newuserform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Add New User</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>

                               
                                <v-col cols="12">
                                    <v-select
                                    v-model="newuserformdata.role"
                                    :items="['T1', 'T2', 'Resident']"
                                    v-on:input="checkrole"
                                    label="Role"
                                    required
                                    ></v-select>
                                </v-col>


                                <v-col cols="12">

                                      <v-select class="mb-3"
                            v-model="newuserformdata.house"
                            :items="houses"
                            v-if="housefield"
                            item-text="item"
                            item-value="item"
                            label="House"
                            persistent-hint
                            single-line
                            ></v-select>


                                    <v-text-field
                                    v-model="newuserformdata.name"
                                    :rules="formRules"
                                    label="Name of user"
                                    required
                                    hint="must be as least 5 characters"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                    v-model="newuserformdata.username"
                                    :rules="formRules"
                                    label="Username"
                                    hint="must be as least 5 characters"
                                    ></v-text-field>
                                </v-col>


                                <v-col cols="12">
                                    <v-text-field
                                    v-model="newuserformdata.email"
                                    v-if="emailfield"
                                    :rules="emailRules"
                                    label="E-mail Address"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                    v-model="newuserformdata.password"
                                    :rules="formRules"
                                    label="Choose Password"
                                    type="password"
                                    hint="must be as least 5 characters"
                                    required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                    v-model="confirmed"
                                    :rules="confirmRules"
                                    label="Confirm Password*"
                                    type="password"
                                    required
                                    ></v-text-field>
                                </v-col>           
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            :loading="btnloading"
                            @click="addnewuser"
                        >
                            Add User
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="newuserform = false,resetform()"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
                
                <!--***************************** Edit User ******************************* -->

        <v-dialog
                v-model="edituserform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Edit User Details</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                                <v-col cols="12">

                                    
                                      <v-select class="mb-3"
                            v-model="edituserformdata.house"
                            :items="houses"
                            v-if="housefield"
                            :rules="houseRules"
                            item-text="item"
                            item-value="item"
                            label="House"
                            persistent-hint
                            single-line
                            ></v-select>

                                    <v-text-field
                                    v-model="edituserformdata.name"
                                    :rules="formRules"
                                    label="Name of user"
                                    required
                                    hint="must be as least 5 characters"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                    v-model="edituserformdata.username"
                                    :rules="formRules4"
                                    label="Username"
                                    hint="must be as least 4 characters"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-select
                                    v-model="edituserformdata.role"
                                    :items="['T1', 'T2', 'Resident']"
                                    v-on:input="checkrole()"
                                    label="Role"
                                    required
                                    ></v-select>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                    v-model="edituserformdata.email"
                                    v-if="emailfield"
                                    :rules="emailRules"
                                    label="E-mail Address"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    
                                    
                                     <v-checkbox v-if="passwordfield"
                              v-model="editpassword"
                              label="Change Password"
                             ></v-checkbox>



                               <v-text-field v-if="selfedit && editpassword"
                                    :disabled="!editpassword"
                                    v-model="oldpw"
                                    label="Current Password"
                                    type="password"
                                    required
                                    ></v-text-field>

 

                                    <v-text-field v-if="passwordfield && editpassword"
                                    :disabled="!editpassword"
                                    v-model="edituserformdata.password"
                                    :rules="formRules"
                                    label="Choose Password"
                                    type="password"
                                    hint="must be as least 5 characters"
                                    required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field v-if="passwordfield && editpassword"
                                    :disabled="!editpassword"
                                    v-model="confirmed"
                                    :rules="confirmRules"
                                    label="Confirm Password*"
                                    type="password"
                                    required
                                    ></v-text-field>


                                </v-col> 
                                <div v-if="!selfedit">  
                                <v-col v-if="editpassword"> 
                                    <v-icon color="red" large>mdi-alert</v-icon>
                                    <span class="text-button">
                                        DONT CHANGE A USERS PASSWORD WITHOUT THEIR PERMISSION
                                    </span>
                                </v-col>   </div>     
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="submitedituser"
                        >
                            Edit User
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="edituserform = false,resetform()"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        
                



 <!--***************************** DELETE User ******************************* -->





            </div>  
                </v-col>
                </v-row>


          <v-dialog
                v-model="deleteuserform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Delete User?</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                               
                               <p>Are you sure you want to delete {{ deletename }}?</p>    
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="submitdeleteuser"
                        >
                            Delete User
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteuserform = false,resetform()"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        

<!--***************************** USER HISTORY ******************************* -->

      <v-dialog
                v-model="userhistory"
                persistent
                max-width="900px">
                <v-card>
                    <v-form ref='form'>
                        
                        <v-card-title class="mb-5">
                         <v-row class="d-flex justify-center"><v-col cols=12 class="d-flex justify-center">  <p class="headline mb-n5">User History</p> </v-col>
                         <v-col cols=12 class="d-flex justify-center">   <p class="body-2">{{ userofhistory.Name }}</p> </v-col></v-row>
                        </v-card-title>
                        <v-card-subtitle>
                        <span class="mt-5">Days:</span> <v-row class="mt-n14 ml-8"> <v-col cols=2><v-select v-model="historyday" :items="historydays" v-on:input="viewUserhistory(userofhistory,historyday);"></v-select> </v-col>
                      <span class="text-h4 mt-6 mr-1">/</span>  <v-btn class="ml-n3 mt-8" small text> <v-icon v-on:click="historypicker=!historypicker">mdi-calendar-range</v-icon> </v-btn>
                         <span v-if="historyday == ''" class="text-h6 mt-7 mr-1">{{ datetext }}</span>

       <v-date-picker
       class="mr-10"
       full-width
       :min="mindate"
       :max="maxdate"
        v-if="historypicker"
        v-model="dates"
        range
        :selected-items-text="datetext"
      >
      
        <template v-slot:default>
           <div class="mx-auto"><v-btn v-on:click="viewrangehistory(dates)">Update</v-btn><v-btn v-on:click="historypicker=false" text small>Cancel</v-btn></div>
       </template></v-date-picker>

                          </v-row>
                       

                        </v-card-subtitle>
                        <v-card-text>

                        <v-container>   



      <v-data-table class="historytable mt-n5"
      :headers="historyheaders"
      :items="history"
      sort-by="date"
      :sort-desc="true"
      :items-per-page=50

      >

       <template v-slot:item.userid>
           <span>{{ userofhistory.Name }}</span>
       </template>

       <template v-slot:item.house>
           <span>{{ userofhistory.house }}</span>
       </template>

        <template v-slot:item.flagged="{ item }">
           <span class="green--text" v-if="item.flagged == null">Clear</span>
           <span class="red--text" v-if="item.flagged == 'true'">Yes</span>
           <span class="bold--text" v-else>{{ item.flagged }}</span>
       </template>

       <template v-slot:item.ontime="{ item }">
           <span v-if="item.ontime =='true'"></span>
           <span v-else>{{ item.ontime }}</span>
       </template>




      </v-data-table>


                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="blue darken-1"
                            text
                            @click="userhistory = false,resetform(),historyday=7"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        






            
    <!-- ************************************************* SNACKBARS************************************  -->
        <v-snackbar
                centered
                style="z-index: 9999"
                color="green"
                v-model="successSB"
                :timeout=2000
                > Change Successfully Submitted
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="successSB = false"
                    >
                    X
                    </v-btn>
                </template>
    </v-snackbar>

            <v-snackbar
                centered
                style="z-index: 9999"
               
                v-model="errorSB"
                :timeout=2000
                > An Error Occured
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="errorSB = false"
                    >
                    Close
                    </v-btn>
                </template>
    </v-snackbar>
        
    </div>
</template>


<script>


import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import axios from 'axios'
import navbar from './../components/navbar'

export default {
    name:'users',
    components: {'navbar':navbar},
    mounted: function(){
        this.chosenhouse=this.currentuser.house
    },
    data: function () {
        return {
            successSB:false,
            search:'',
            errorSB:false,
            oldpw:'',
            passwordfield:false,
            btnloading:false,
            selfedit:false,
            selected:'',
            newuserhouse:'',
            housefield:true,
            edituserhouse:'',
            chosenhouse:'',
            houses:['Broadview Village','House 3','Oriole Parkway','Ridley','House 1','House 2','Coach House','Meldazy','Hancock','Spruce Hill',"Lamoreaux","Townhouse"],
            emailfield:false,
            editpassword:false,
            deletename:'',
            dates:[],
            history:[],
            newuserform:false,
            userhistory:false,
            historypicker:false,
            historydays:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
            historyday:7,
            userofhistory:{name:""},
            edituserform:false,
            deleteuserform:false,
            edituserid:'',
            deleteuserid:{userid:0},
            formRules: [
            v => !!v || 'Field is required',
            v => v.length > 4 || 'Must be at least 5 characters',],
            houseRules: [
            v => !!v || 'Field is required',],
            formRules4: [
            v => !!v || 'Field is required',
            v => v.length > 3 || 'Must be at least 4 characters',],
            confirmRules: [
            v => !!v || 'Field is required',
            v => v == ((this.newuserformdata.password)||(this.edituserformdata.password)) || 'Does not match password',],
            emailRules: [
            v => /.+@.+/.test(v) || 'E-mail must be valid',],
            confirmed:'', 
            newuserformdata:{
                name:"",
                username:"",
                role:"",
                house:"",
                email:"",
                password:"",
            },
            edituserformdata:{
                name:"",
                username:"",
                role:"",
                house:"",
                email:"",
                password:"",
            },
            selecteduser:'',
            headers:[
                     {text:'Name',value:'Name'},
                     {text:'Username',value:'Username'},
                     {text:'Role',value:'Role',
                     filter: value => {
                        return value != 'T2'},},
                     {text:'House',value:'house'},
                     {text:'email',value:'email'},
                     {text:'Actions',value:'actions',sortable:false}],

            historyheaders:[
                     {text:'Name',value:'userid',sortable:false},
                     {text:'House',value:'house',sortable:false},
                     {text:'Date',value:'date'},
                     {text:'Time',value:'time'},
                     {text:'Flagged',value:'flagged',sortable:false},
                     {text:'Action Taken',value:'t1result',sortable:false},
                     {text:'T2 Notified', value:'escalated',sortable:false},
                     {text:'Note',value:'ontime',sortable:false}
                     ],
            
            t2headers:[
                     {text:'Name',value:'Name'},
                     {text:'Username',value:'Username'},
                     {text:'Role',value:'Role',
                      filter: value => {
                        return value == 'T2'},},
                     {text:'email',value:'email'},
                     {text:'Actions',value:'actions',sortable:false}],

        
        }
        },
    methods: {
      ...mapActions({

        logout: 'logout',

      }),

      submitlogout () {
          try{this.logout().then(() => {

          if(this.authenticated == false){
          this.$router.replace({
            name: 'login'
          });console.log('logged out')} else {console.log('auth boolean '+this.authenticated)
              console.log('logout failed')}

          })}catch (e) {console.log('logout failed outside')}
    
      },
      checkrole() {
          if ((this.newuserformdata.role == 'Resident')||this.edituserformdata.role == 'Resident'){this.emailfield=false}
          else{this.emailfield=true}
          if ((this.newuserformdata.role == 'T2')||(this.edituserformdata.role == 'T2')){this.newuserformdata.house=null;this.housefield=false}
          else{this.housefield=true}
      },
      resetform () {
        this.$refs.form.resetValidation();
        this.editpassword=false,
        this.emailfield=false,
        this.oldpw='',
        this.confirmed = ''
        this.newuserformdata ={
                name:"",
                username:"",
                role:"",
                house:"",
                email:"",
                password:"",
            }
        this.edituserformdata={
                name:"",
                username:"",
                userid:"",
                role:"",
                house:"",
                email:"",
                password:"",

            }
      },
      updateusers() { axios.get('/users', {
            headers: {
                "Authorization": "Bearer "+this.token}}).then(
                    (resp)=>{
                        this.users=resp.data;
                }
            )
      },

    viewUserhistory(user,days){

        console.log(user)
        this.userofhistory=user
        this.history=[]
        this.historypicker=false
        this.dates=[]
        var historydate = new Date()
        var newd = historydate.getDate()

        for (let i = 0; i<days;i++){
        
        axios.get('/questionaire/day/'+historydate.toISOString().slice(0, 10),{headers: {"Authorization": "Bearer "+this.token}}).then( //get current day
                (resp) => {
                    let rdate = resp.config.url.slice(-10)
                    console.log(rdate)
                    let r = resp.data;
                    let ru = []
                    for (let j=0;j<r.length;j++) //for every result
                    {
                        if((r[j].userid == user.userid)){r[j].date=r[j].completion.slice(0,10);r[j].time=r[j].completion.slice(11,16); ru.push(r[j])} //get the users results into the list
                        else{''}

                    }

                    console.log(ru)
                    console.log(ru.length)
                    if ( ((ru.length==0) && (i==0))) {this.history.push({"date":rdate,"time":"","flagged":"No Completions Yet Today"})}
                    if ( ((ru.length==0) && (i!=0))) {this.history.push({"date":rdate,"time":"","flagged":"Missed Both Daily Screeners"})}

                    if (ru.length==1){//if there is one result
                        console.log(`date has one completion`)
                        if (ru[0].ontime != 'late') {    //one question and not late means it was completed ontime in the morning
                            
                            console.log(`completion is not late`);
                            console.log(`adding questionaire plus afternoon message`)
                            this.history.push(ru[0])//question info
                            this.history.push({"date":ru[0].initialized.slice(0, 10),"time":"","flagged":"Did Not Complete Second Screener"}) //message for afternoon
                        
                        
                        }
                        
                        if (ru[0].ontime == 'late') { //one and late means late morning
                            this.history.push(ru[0])
                            //this.history.push({"date":historydate.toISOString().slice(0, 10),"time":"LATE Morning Screener","flagged":""})
                            console.log(`length 1 LATE,morning screener somewhere`)
                            this.history.push({"date":ru[0].initialized.slice(0, 10),"time":"","flagged":"Did Not Complete Second Screener"})
                            
                        }
                        
                        } 


                    if (ru.length==2){
                        console.log(`date has two completions`)
                        if (( (ru[0].ontime == 'true') || (ru[0].ontime == null)  ) && ((ru[1].ontime==null) || (ru[1].ontime == 'true'))    ) {

                            this.history.push(ru[0])
                            this.history.push(ru[1])


                        }

                        if ((ru[0].ontime == 'early') || (ru[1].ontime == 'early') ) {

                            this.history.push(ru[0])
                            this.history.push(ru[1])
                            //this.history.push({"date":historydate.toISOString().slice(0, 10),"time":"EARLY Afternoon Screener","flagged":""})

                        }

                        if ((ru[0].ontime == 'late') || (ru[1].ontime == 'late') ) {
                            
                            this.history.push(ru[0])
                            this.history.push(ru[1])
                            //this.history.push({"date":historydate.toISOString().slice(0, 10),"time":"LATE Morning Screener","flagged":""})
  

                        }
                        else{''}

                    }
                    else{''}
                          
               })
        if (i == days -1){''}

        else{  console.log(`old date number - ${newd}`)
        newd = historydate.getDate()       
        newd --
        console.log(`new date number - ${newd}`)
        historydate.setDate(newd)
        console.log(`new date - ${historydate}`)   
        console.log(this.history)}

      
        }

        //get the real data and replace place holders




     //get todays string
     //get todays date number from the string
        
/*             
            console.log(`start of loop on ${historydate.toISOString().slice(0, 10)} count is ${count}`)
            axios.get('/questionaire/day/'+historydate.toISOString().slice(0, 10),{headers: {"Authorization": "Bearer "+this.token}}).then(
                (resp) => {
                    let r = resp.data;
                    for (let j=0;j<r.length;j++)
                        {if (r[j].userid == user.userid)
                            {this.history.push(r[j],console.log(`pushed a date, should minus 1`))
                            if(count > 0)
                            {count --;console.log(`count reduced to ${count}`)}
                            else{continue}}
                        else{continue}}}).then( () => {  if(count>0){
                        for(let y=0;y<count;y++){
                            console.log(`the count is ${count}`)
                            this.history.push({"initialized":historydate.toISOString().slice(0, 10),"userid":"Failed Completion Period"})
                                }}
                    else{''}  }   )
                
                  
                   
                    newd --
                    historydate.setDate(newd)
                
            
        } */
            //get the date
            //axios.get using the datestr
            //filter it by user, and add it to datalist
            //set the new date to be history.getdate -1, repeat on the new date untill 14 days


        this.userhistory = true
    },
    iterate(historydate) {axios.get('/questionaire/day/'+historydate,{headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => {

                //add to history
                //increment the date
                  let rdate = resp.config.url.slice(-10)
                  let today = new Date()
                    
                    let r = resp.data;
                    let ru = []
                    for (let j=0;j<r.length;j++) //for every result
                    {
                        if((r[j].userid == this.userofhistory.userid)){r[j].date=r[j].completion.slice(0,10);r[j].time=r[j].completion.slice(11,16); ru.push(r[j])} //get the users results into the list
                        else{''}

                    }

                   
                    if ( ((ru.length==0) && (historydate==today.toISOString().slice(0, 10) ))) {this.history.push({"date":rdate,"time":"","flagged":"No Completions Yet Today"})}
                    if ( ((ru.length==0) && (historydate!=today.toISOString().slice(0, 10) ))) {this.history.push({"date":rdate,"time":"","flagged":"Missed Both Daily Screeners"})}

                    if (ru.length==1){//if there is one result
                        console.log(`date has one completion`)
                        if (ru[0].ontime != 'late') {    
                            
                            console.log(`completion is not late`);
                            console.log(`adding questionaire plus afternoon message`)
                            this.history.push(ru[0])
                            this.history.push({"date":ru[0].initialized.slice(0, 10),"time":"","flagged":"Did Not Complete Second Screener"})
                        
                        
                        }
                        
                        else {
                            this.history.push(ru[0])
                            this.history.push({"date":ru[0].initialized.slice(0, 10),"time":"","flagged":"Did Not Complete Second Screener"})
                            
                            
                        }
                        
                        } 


                    else if (ru.length==2){
                        console.log(`date has two completions`)
                        if (( (ru[0].ontime == 'true') || (ru[0].ontime == null)  ) && ((ru[1].ontime==null) || (ru[1].ontime == 'true'))    ) {

                            this.history.push(ru[0])
                            this.history.push(ru[1])


                        }

                        if ((ru[0].ontime == 'early') || (ru[1].ontime == 'early') ) {

                            this.history.push(ru[0])
                            this.history.push(ru[1])
                            //this.history.push({"date":historydate.toISOString().slice(0, 10),"time":"EARLY Afternoon Screener","flagged":""})

                        }

                        if ((ru[0].ontime == 'late') || (ru[1].ontime == 'late') ) {
                            
                            this.history.push(ru[0])
                            this.history.push(ru[1])
                            //this.history.push({"date":historydate.toISOString().slice(0, 10),"time":"LATE Morning Screener","flagged":""})

                        }
                        

                    }
                 

                
            } );return true   },
    

    async viewrangehistory(range){
        this.history=[]
        console.log(range)
        console.log(range[0].slice(5,7))
        var historydate = new Date(range[0])
        historydate.setHours(24)
        console.log(historydate)
        var newd = historydate.getDate()
        console.log(newd)
        var date = historydate.toISOString().slice(0, 10)
        console.log(date)
        var i = 0
        while((historydate.toISOString().slice(0, 10) != range[1] && (i<40)))
            {
                    await this.iterate(date)
                    //  newd --;
                    //console.log(`NEW DATE NUMBER: ${newd}`)
                    i ++
                    historydate.setHours(24)
                    console.log(historydate)
                    date=historydate.toISOString().slice(0, 10)
            }

        if (historydate.toISOString().slice(0, 10) == range[1]) {//the final day
                    await this.iterate(date)
                    i ++

        }
        this.historypicker=false
        this.historyday=""
        



    },




     openeditUser(user){

         this.edituserformdata.name = user.Name
         this.edituserformdata.username = user.Username
         this.edituserformdata.email = user.email
         if(user.Role == "T2"){this.housefield=false}
         else{this.housefield=true;this.edituserformdata.house = user.house}
         
         this.edituserformdata.role = user.Role
         this.edituserformdata.userid = user.userid
         this.edituserid = user.userid

        if(user.userid == this.user){this.passwordfield= true;this.selfedit=true;this.warnpassword=false}
        else if(  (user.Role == "T1")  || (user.Role == "T2")   ){this.passwordfield= false;this.selfedit=false;this.warnpassword=false}
        else{this.passwordfield= true;this.warnpassword=true;this.selfedit=false;this.emailfield=false}
        this.checkrole()

         console.log(user)
         console.log(user.Role)
         console.log(this.edituserformdata.role)
         console.log("T2" == user.Role)
         this.edituserform=true


     },

     async addnewuser() {
         this.btnloading = true
          try{await axios.post('/users', this.newuserformdata, {
            headers: {
                "Authorization": "Bearer "+this.token}}).then(() => {

                                this.resetform();
                                this.newuserform = false;
                                this.successSB = true

                    setTimeout( () => {this.$store.dispatch('getusers');this.btnloading=false
                                
                    },700)
                   
                })}catch(e){this.errorSB=true;this.btnloading=false}
         
          //then update
          //then close window
          //then snackbar
      },
    submitedituser() {
        if(this.$refs.form.validate()){ console.log(this.edituserformdata)
        if (this.emailfield==false){this.edituserformdata.email=''}
        if (this.edituserformdata.userid==this.user) {//if own user, check password before changing,
                                            //if password if correct, add it to change
        if (this.oldpw != '') { axios.post('/login', {"username":this.edituserformdata.username,"password":this.oldpw}).then( ()=> {
            try{axios.patch('/users', this.edituserformdata, {
                        headers: {
                            "Authorization": "Bearer "+this.token}}).then(() => {

                                
                                            this.resetform();
                                            this.edituserform = false;
                                            this.successSB = true
                                        
                            
                            })}catch(e){this.errorSB=true}        
            }).catch(()=> {this.errorSB=true})}
        else { try{axios.patch('/users', this.edituserformdata, {
                        headers: {
                            "Authorization": "Bearer "+this.token}}).then(() => {

                                
                                            this.resetform();
                                            this.edituserform = false;
                                            this.successSB = true
                                        
                            
                            }).catch( () => {this.errorSB=true} )}catch(e){this.errorSB=true}}
           
        }
        else {
            try{axios.patch('/users', this.edituserformdata, {
                        headers: {
                            "Authorization": "Bearer "+this.token}}).then(() => {

                                
                                            this.resetform();
                                            this.edituserform = false;
                                            this.successSB = true
                                        
                            
                            })}catch(e){this.errorSB=true}        
            
            
            
            }setTimeout(() => {this.$store.dispatch('getusers')},500)}
        else{this.errorSB=true}
       
              
                
                },
    

    opendeleteUser(item){
        this.deletename= item.Name;
        this.deleteuserid.userid=item.userid
        this.deleteuserform=true
    },

    submitdeleteuser(){
        axios.delete('/users',{data:this.deleteuserid,
            headers: {
                "Authorization": "Bearer "+this.token}}).then( ()=> {

                      setTimeout(() => {this.$store.dispatch('getusers')},500);
                                this.deleteuserform = false;
                                this.successSB = true
                             
                })

    },

    
         







    },
    computed:{
        ...mapGetters({
            authenticated: 'authenticated',
            token:'token',
            user:'user',
            users: 'users'
        }),
          currentuser(){
            var current={};
            for(let i=0;i<this.users.length;i++){
                if (this.users[i].userid == this.user){current = this.users[i]}
                else{continue}
            }return current
        },
        datetext () {
        return this.dates.join(' - ')
      },
      mindate() {

          if(this.dates.length == 1){return this.dates[0]}
          else{return null}
      },
      maxdate() {
          return new Date().toISOString().slice(0, 10)
      }


    }

    }





</script>

<style>

.historytable td {
    font-size: 12px !important;
}




</style>