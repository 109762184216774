<template>
    <v-container>
        <navbar></navbar>
      <div class="text-center">
          
                <v-data-table
                :headers="headers"
                show-expand
                :items="groups"
                :expanded.sync="expanded"
                :single-expand="true"
                item-key="name"
                :search="search"
                :items-per-page="20"
                class="elevation-1">
                    <template v-slot:item.name="{ item }">
                        <span class="text-h4">
                            {{item.name}}     
                        </span>

                    
                    </template>

                      <template v-slot:expanded-item="{ item }">
                        <div class="text-right">       
                            <v-icon   
                            large
                            class="mr-6 mt-4 mb-4"
                           @click="openrenamegroupform(item)"
                            >

                                mdi-pencil
                            </v-icon>

                            <v-icon
                            large
                            class="mr-6 mt-4 mb-4"
                         @click="opendeletegroupform(item)"
                            >
                            mdi-delete
                            </v-icon></div>
                         
                            <v-data-table class="mb-10"
                            hide-default-footer
                            :items="item.users"
                            :headers="qheaders">
                            
                            <template v-slot:item.actions="{ item }">

                                <v-icon 
                                @click="opendeletefromgroupform(item)"
                            class="mr-6 mt-4 mb-4"
                         
                            >
                            mdi-delete
                            </v-icon>


                            </template>



                                </v-data-table>

                                <div class="text-left mb-10 ml-2"> 
                                    <v-btn @click='openaddtogroupform(item)' >
                                    Add to Group
                                    </v-btn>
                               
                                
                                </div>
      
                           
                            </template>

                            </v-data-table>
                         <div v-if="!loading" class="text-left mt-5 ml-2"> 
                                    <v-btn @click="opennewgroupform">
                                    Create New Group
                                    </v-btn>
                                </div>

    <!-- ***********************************************************     NEW GROUP   *********************************************************************************************** -->

                       <v-dialog
                v-model="newgroupform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Add New Group</span>
                        </v-card-title>
                        

                        <v-card-text>
                        <v-container>   
                           <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="newgroupformdata.name"
                                    :rules="formRules"
                                    label="Name of Group"
                                    required
                                    hint="must be as least 5 characters"
                                    ></v-text-field>
                                </v-col>
                           <v-col cols=12><span class="headline">Select Group Members:</span></v-col>

                            <v-list flat>
                                <v-list-item-group  v-for="item in users"
                                :key="item.userid">

                                <v-list-item class="mt-n10"  v-if="(item.Role=='T1')||(item.Role=='T2')">                     
                             <v-list-item-content>

                             <v-checkbox
                             multiple
                            v-model="newgroupformdata.users"
                            :label="item.Name"
                            :value="item.userid"
                            ></v-checkbox>

                             </v-list-item-content>
                                
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>



                          
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                         :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            @click="addnewgroup"
                        >
                            Add Group
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closenewgroupform"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
      


<!--    ************************************ DELETE GROUP FORM ***************************************************** -->     
 
        <v-dialog
                v-model="deletegroupform"
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Delete Group?</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                               
                               <p>Are you sure you want to delete the group "{{grouptodelete.name}}"?</p>    
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            @click="submitdeletegroup(grouptodelete)"
                        >
                            Delete Group
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="deletegroupform=false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog> 


<!-- ************************************************************* RENAME GROUP    *********************************************************************************************** -->


        <v-dialog
                v-model="editgnameform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Rename Group</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                               
                               <p>Enter new name for group "{{ grouptorename.name }}"":
                                   </p>    
                            </v-row>

                         <v-row>
                               
                             <v-col cols=8> <v-text-field v-model="newgname"></v-text-field> </v-col>

                            </v-row>


                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            @click="renamegroup"
                        >
                            Rename Group
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="editgnameform = false;newgname = ''"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        



 <!--    ************************************ADD to GROUP FORM ***************************************************** --> 

             <v-dialog
                v-model="addtogroupform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Add to Group</span>
                        </v-card-title>
                        

                        <v-card-text>
                        <v-container>   
                           <v-row>
                           <v-col cols=12><span class="headline">Select Available New Group Members:</span>              
                           <p v-if="(available.length==0)" class="caption mt-8">All available admins have been added</p>
                           </v-col>



                            <v-list flat>

                                <v-list-item-group  >

                                <v-list-item class="mt-n5" 
                               v-for="user in available"
                                :key="user.userid"
                                >

                             <v-list-item-content>

                             <v-checkbox
                             multiple
                             v-model="newgroupmembers"
                            :label="user.Name"
                            :value="user.userid"
                            ></v-checkbox>

                             </v-list-item-content>
                                
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>



                          
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="addtogroup"
                        >
                            Add to Group
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeaddtogroupform"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>



           <!--    ************************************DELETE from GROUP FORM ***************************************************** -->          
            
                     <v-dialog
                v-model="removefromgroupform"
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Delete from Group?</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>                     
                               <p>Remove {{usertodelete.Name}} from the group "{{ usertodelete.group }}"?</p>    
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removefromgroup()"
                        >
                            Remove From Group
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removefromgroupform=false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
                


       <v-snackbar
                centered
                style="z-index: 9999"
                v-model="invalidSB"
                :timeout=2000
                > An Error Occured
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="invalidSB = false"
                    >
                    X
                    </v-btn>
                </template>
    </v-snackbar>


            <v-snackbar
                centered
                style="z-index: 9999"
                color="green"
                v-model="successSB"
                :timeout=2000
                > Change Successfully Submitted
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="successSB = false"
                    >
                    x
                    </v-btn>
                </template>
    </v-snackbar> 

      
      
      
      </div>  


    </v-container>
</template>



<script>
import navbar from './../components/navbar'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    name: 'groups',
    components: {'navbar':navbar},
    mounted:function(){
                    axios.get('/users/email', {headers: {"Authorization": "Bearer "+this.token}}).then((resp)=>
                    { 
                        var tmp=resp.data;
                        let keys = Object.keys(tmp)
                        for(let i=0;i<keys.length;i++) {
                            let obj = {"name":'',"data":[],"users":[]}
                            tmp[keys[i]].push(keys[i]),
                            obj["name"]=keys[i]
                            obj["data"]=tmp[keys[i]]
                            this.groups.push(obj)
                        }
                        for (let j=0;j<this.users.length;j++){

                            for(let k=0;k<this.groups.length;k++){

                                for(let u=0;u<this.groups[k].data.length;u++){
                                    if(this.users[j].userid == this.groups[k].data[u].userid){let clone = JSON.parse(JSON.stringify(this.users[j]));this.groups[k].users.push(clone)}
                                    else{continue}
                                }for(let f=0; f<this.groups[k].users.length;f++){
                                    this.groups[k].users[f]["group"]=this.groups[k].name
                                }

                            }
                            
                            }
                        
                        });console.log(this.groups)

    },
    data:function(){
        return{
            groups:[],
            available:[],
            newgroupform:false,
            grouptorename:{},
            successSB:false,
            grouptodeletefrom:'',
            usertodelete:{"group":""},
            removefromgroupform:false,
            grouptoaddto:{},
            newgroupmembers:[],
            addtogroupform:false,
            newgname:'',
            bloading:false,
            editgnameform:false,
            deletegroupform:false,
            grouptodelete:{},
            invalidSB:false,
             newgroupformdata: {
                name:'',
                users:[]
            },
            headers:[
                     {text:'Group',value:'name'},
                      {text: 'Expand',value: 'data-table-expand' },
                    ],
             qheaders:[
                     {text:'User',value:'Name', sortable:false},
                     {text:'Role',value:'Role',sortable:false},
                     {text:'Email',value:'email',sortable:false},
                     {text:'Actions',value:'actions',sortable:false},

                    ],        
            expanded:[],
             formRules: [
            v => !!v || 'Field is required',
            v => v.length > 4 || 'Must be at least 5 characters'],
            search:''
        }
    },
    methods:{

         opennewgroupform() {
          this.newgroupform=true
      },

        opendeletegroupform(item) {
            this.grouptodelete=item
          this.deletegroupform=true
      },

      opendeletefromgroupform(item){
          this.grouptodeletefrom=item
          this.usertodelete=item
          this.removefromgroupform=true
      },
        openrenamegroupform(item){
            this.grouptorename=item
            this.newgname=item.name
            this.editgnameform=true
        },
        openaddtogroupform(group){
            this.newgroupmembers=[],
            this.grouptoaddto=group;
            console.log(this.users)
            console.log(group)
            var ids = []
            for(let k=0;k<group.users.length;k++){
                ids.push(group.users[k].userid)
            }
            for(let i=0;i<this.users.length;i++){
                if(this.users[i].Role == 'Resident'){''}

                else{
                    if(ids.includes(this.users[i].userid)){continue}
                    else{this.available.push(this.users[i])}
                }
               
                
            }
            this.addtogroupform=true
            

        },
        updategroups() {
            this.groups = []
                axios.get('/users/email', {headers: {"Authorization": "Bearer "+this.token}}).then((resp)=>
                    { 
                        var tmp=resp.data;
                        let keys = Object.keys(tmp)
                        for(let i=0;i<keys.length;i++) {
                            let obj = {"name":'',"data":[],"users":[]}
                            tmp[keys[i]].push(keys[i]),
                            obj["name"]=keys[i]
                            obj["data"]=tmp[keys[i]]
                            this.groups.push(obj)
                        }
                            for (let j=0;j<this.users.length;j++){

                            for(let k=0;k<this.groups.length;k++){

                                for(let u=0;u<this.groups[k].data.length;u++){
                                    if(this.users[j].userid == this.groups[k].data[u].userid){let clone = JSON.parse(JSON.stringify(this.users[j]));this.groups[k].users.push(clone)}
                                    else{continue}
                                }for(let f=0; f<this.groups[k].users.length;f++){
                                    this.groups[k].users[f]["group"]=this.groups[k].name
                                }

                            }
                            
                            }
                        
                        })


        },
        closenewgroupform(){
            this.newgroupform=false
            this.newgroupformdata={
                name:'',
                users:[]
            }
        },
        closeaddtogroupform(){
            this.available=[]
            this.addtogroupform=false
        },

        submitdeletegroup(group) {
            this.bloading=true
          axios.delete('/users/email',{data:{"name":group.name},
            headers: {
                "Authorization": "Bearer "+this.token}}).then (()=> {


                       setTimeout(()=>{
                        axios.get('/users/email', {
                     headers: {"Authorization": "Bearer "+this.token}}).then(
                    (resp)=>{
                        this.groups=resp.data;
                        this.successSB=true;
                        this.updategroups()
                        this.deletegroupform=false
                        this.bloading=false})
                        
                    },300)

                }).catch( () => {this.invalidSB=true;this.bloading=false} )

          console.log(group)
      },

        addnewgroup() {
            this.bloading=true
          console.log(this.newgroupformdata);
          try{ axios.post('/users/email',this.newgroupformdata, {
            headers: {
                "Authorization": "Bearer "+this.token}}).then( () => {

                     setTimeout(()=>{
                       
                        this.successSB=true;
                        this.updategroups()
                        this.newgroupform=false
                        this.bloading=false
                    
                    
                    },500)}
                   

                ).catch( ( ) => {this.invalidSB=true;this.bloading=false })}

          catch (e){this.invalidSB=true;this.bloading=false}
         

      },
      renamegroup(){
          this.bloading=true
          axios.patch('/users/email',{"name":this.grouptorename.name,"newname":this.newgname},{
            headers: {
                "Authorization": "Bearer "+this.token}}).then(
                    () => {
                     setTimeout(()=>{
                        this.successSB=true;
                        this.updategroups()
                        this.editgnameform=false
                        this.bloading=false
                    },500)}
                ).catch(( ) => {this.invalidSB=true;this.bloading=false })
      },

        addtogroup(){
            console.log(this.newgroupmembers)
            this.bloading=true
            for(let i=0;i<this.newgroupmembers.length;i++){
                
                axios.post(`/users/email/${this.grouptoaddto.name}`,{"userid":this.newgroupmembers[i]}, {
            headers: {
                "Authorization": "Bearer "+this.token}})
            }
                       setTimeout(()=>{
                       this.updategroups()
                        this.successSB=true;
                        this.closeaddtogroupform()
                        this.bloading=false
                   
                    },600)

        },

       removefromgroup() {
        this.bloading=true
          axios.delete(`users/email/${this.usertodelete.group}`,{data:{"userid":this.usertodelete.userid},
            headers: {
                "Authorization": "Bearer "+this.token}}).then (()=> {

                      setTimeout(()=>{
                        
                        
                        this.successSB=true;
                        this.removefromgroupform=false
                        this.updategroups()
                        this.bloading=false
                        
                        
                    },300)

                })

      },




    },
      computed:{
        ...mapGetters({
            authenticated: 'authenticated',
            token:'token',
            user:'user',
            users: 'users'
        }),
      }
    
}

</script>