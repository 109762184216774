<template>
    <div>
        <navbar></navbar>
 

            <div>
               <v-row class="d-flex justify-center mt-5 mb-5">  <!-- DATE SELECTOR  -->
                    <v-col cols=6>

                         <v-select
                            v-model="chosenhouse"
                            :hint="`Select House`"
                            :items="houses"
                            item-text="item"
                            item-value="item"
                            label="House"
                            persistent-hint
                            single-line
                             :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                                            }"
                            >
                             <template v-slot:prepend-item>
                        
                         <v-list-item
                             @click="chosenhouse = ''"
                            ><span>All Houses</span></v-list-item> </template>

                            >
                            
                            </v-select>
                         
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                        <v-col cols=5>

                             <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                     min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="date"
                                            label="Select Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on">
                                            </v-text-field>
                                        </template>
                                    <v-date-picker
                                    :max="maxdate"
                                     v-model="date"
                                     @input="menu2 = false;logdate"
                                    ></v-date-picker>
                               </v-menu>
                        </v-col>

<!--******************************* DATA TABLE****************************************************************  -->
                 
                    </v-row>                                                
                    <v-row class="d-flex justify-center mt-n7">
                        <v-col cols=12 class="d-flex justify-center">

                      <v-col cols=8>   <v-data-table v-for="(user,i) in tableusers"
                                :key="user"
                                v-show="(user.house == chosenhouse)||(chosenhouse == '')"
                                :index="i"
                                no-data-text="NO COMPLETIONS"
                                hide-default-footer
                                hide-default-header
                                :headers="headers"
                                :items="user.completions"
                                :items-per-page="5"
                                class="elevation-1 mb-10"
                            >

                              <template v-slot:top>
                                  <span class="mb-5 ml-5 text-h5">
                                {{ user.Name }}</span>

                                
                                


                                </template>


                               <template v-slot:item.completions[0]="{ item }">





                         <div class="mt-4">   <span class='text-body-1'
                                color="red"
                                dark>

                                Questionaire #{{item.number}} Completed: {{new Date(item.initialized).toLocaleString('en-US',{timezone:"America/New_York",timeStyle:"short",dateStyle:"medium"})}}
                                

                            </span> </div>

                         


                            <span class="mr-4 text-h6" v-if="(item.ontime != 'true')">{{item.ontime.replace(/^\w/, (c) => c.toUpperCase())}}</span>
                            <v-spacer></v-spacer>

                            <div class="mt-4">
                            <span class="mr-4">Status:</span> 
                            <span v-if="item.flagged == null" class="text-h6 green--text">Clear</span> 
                            <span v-if="item.flagged == 'true' && item.t1result == null"  class="text-h6 red--text">Flagged- Requires Tier 1 Review</span> 
                            <span v-if="item.t1result != null && item.t2result == null && item.escalated == 'Yes'"  class="text-h6 orange--text">Validated- Requires Tier 2 Review</span> 
                            <span v-if="item.t2result != null || item.escalated == 'No'"  class="text-h6 blue--text">Action Taken</span> </div>
                            
                            <v-spacer></v-spacer>
                                        <v-btn @click="opendetails(item)" class="mb-10 mt-5">Review</v-btn>
                            </template>

                                <template v-slot:footer>
                                <div class="mt-3 ml-2 text-subtitle">  <span v-if="((user.completions.length == 0)&&( ((noon)||(beforetoday) ) ))">MISSED MORNING SCREENER </span></div>
                                <div class="mt-3 ml-2 text-subtitle">  <span v-if="((user.completions.length == 0)&&( ((evening)||(beforetoday) ) ))">MISSED EVENING SCREENER </span></div>
                                <div class="mt-3 ml-2 text-subtitle">  <span v-if="((user.completions.length == 1)&&(((evening)||(beforetoday) ) ))">MISSED EVENING SCREENER </span></div>
                                </template>
                            
                            </v-data-table></v-col>
                        </v-col>                 
                    </v-row>

 <!-- *****************************************DIALOGS************************************************************* -->

        
         <v-dialog
                v-model="details"
                persistent
                max-width="600px">
                <v-card>
                     <v-col cols=12 class="d-flex justify-end mb-n15"> <v-btn @click="closereview" text class="mb-5">
                        X
                    </v-btn></v-col>
                    <v-form ref='form'>
                        <v-card-title>
                          <v-col cols=12>  <span class="headline">Questionaire Review</span></v-col>
                        <v-col cols=12><span>{{respondent}} | </span>
                         <span> {{new Date(questionairetoview.initialized).toLocaleString('en-US',{timezone:"America/New_York",timeStyle:"short",dateStyle:"medium"})}} </span></v-col>   
                        </v-card-title>
                        

                        <v-card-text>
                        <v-container class="mt-n10">   
                           <v-row>
                                <v-col cols="12">
                                </v-col>
                            <v-list flat>
                                

                                <v-list-item v-for="(question,j) in questionstoreview"
                                :key="question"
                                :index='j' >                     
                             <v-list-item-content>

                             <span class="text-subtitle-1 font-weight-bold">{{j+1}}) {{question.question}}</span>
                                    <span class="text-subtitle-2 mb-3 font-weight-bold">{{question.answer}}</span>
                                    <span v-if="((question.answer == question.flaganswer)  ||  ((question.flagupper != null)&&((parseFloat(question.answer)<parseFloat(question.flaglower))||(parseFloat(question.answer)>parseFloat(question.flagupper)))))" class="text-body red--text font-weight-bold mb-2">Flagged!</span>
                                    
                             </v-list-item-content>
                                
                                </v-list-item>
                               
                            </v-list>                          
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <p v-if='awaitingt2 ||  questionairetoview.t2id != null' class="d-flex justify-center">__________________________________________________</p>

                <v-col v-if='awaitingt2 ||  questionairetoview.t2id != null' class="d-flex justify-center" cols=12> <span class="text-h6">FOLLOW UP</span> </v-col>


                    <v-simple-table class="mb-10" v-if='awaitingt2 ||  questionairetoview.t2id != null'>
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left">
                                Teir
                            </th>
                            <th class="text-left">
                                Validated By:
                            </th>
                            <th class="text-left">
                                Time:
                            </th>
                            <th class="text-left">
                                Details:
                            </th>
                            <th class="text-left">
                                T2 Notified?:
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            >
                            <td>1</td>
                            <td><span v-if='awaitingt2 ||  questionairetoview.t2id != null'>{{ questionairetoview.t1id }}</span></td>
                            <td><span>{{ new Date(questionairetoview.t1time).toLocaleString('en-US',{timezone:"America/New_York",timeStyle:"short",dateStyle:"medium"}) }}</span></td>
                            <td><span>{{ questionairetoview.t1result }}</span></td>
                            <td><span>{{ questionairetoview.escalated }}</span></td>
                            </tr>

                             <tr v-if="questionairetoview.t2id != null"
                            >
                          <td>2</td>
                            <td><span>{{ questionairetoview.t2id }}</span></td>
                            <td><span>{{ new Date(questionairetoview.t2time).toLocaleString('en-US',{timezone:"America/New_York",timeStyle:"short",dateStyle:"medium"}) }}</span></td>
                            <td><span>{{ questionairetoview.t2result }}</span></td>
                            <td><span>N/A</span></td>
                            </tr>

                        </tbody>
                        </template>
                    </v-simple-table>


                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-text>
                    

                    <div id="t1"> 
                     
                     <v-col v-if='awaitingt1 && activet1' class="d-flex justify-center" cols=12> <p class="text-h6 font-weight-medium">Tier 1 Review</p>  </v-col>
                      <v-col v-if='awaitingt1 && activet1' class="d-flex" cols=12> <p class="text-h6 font-weight-medium">Select A Result</p>  </v-col>
                         <v-col v-if='awaitingt1 && !activet1' class="d-flex justify-center" cols=12> <p class="text-h6 font-weight-medium">Awaiting Tier 1 Validation</p>  </v-col>

                                 
                                  <div v-if='awaitingt1 && activet1'>  <v-select 
                        v-model="t1action"
                        :items="actions"
                        item-text="item"
                        item-value="item"
                        label="Select"
                        return-object
                        single-line
                        ></v-select>


                            <v-text-field v-if="t1action == 'Other'"
                            v-model="t1otheraction"
                            label="Describe Status"
                        ></v-text-field>

                                <p class="text-subtitle-2 mt-5">Do you wish to notify an RN / Tier 2?</p>
                                    
                                     <v-checkbox class="mb-n5"
                                     :disabled="notifyt2[0]=='No'"
                                    v-model="notifyt2"
                                    label="Yes"
                                    value='Yes'
                                    ></v-checkbox>

                                    <v-checkbox
                                    :disabled="notifyt2[0]=='Yes'"
                                    v-model="notifyt2"
                                    label='No'
                                    value='No'
                                    ></v-checkbox>

                                    <v-btn :loading="buttonloader" v-if="questionairetoview.t1id == null" @click="submitreview1" class="mb-15 mt-10"
                                     :disabled="((t1action == '' || buttonloader == true)||(notifyt2.length == 0))">
                                        SUBMIT VALIDATION
                                    </v-btn>
                                    
                                    
                                    </div>

                    </div>


                 
    
                    
                


           <div id="t2" v-if="questionairetoview.t1id != null && questionairetoview.escalated == 'Yes'">   
               <v-col v-if="activet2 && questionairetoview.t2id == null" class="d-flex justify-center" cols=12> <p class="text-h6 font-weight-medium">Tier 2 Review</p>  </v-col>      
                        <v-col v-if="questionairetoview.t2id == null && awaitingt2 && activet1 && questionairetoview.escalated == 'Yes'" class="d-flex justify-center" cols=12> <p class="text-h6 font-weight-medium">Awaiting Tier 2 Review</p>  </v-col>
                      
                    <v-col v-if="activet2 && questionairetoview.t2id == null" class="d-flex" cols=12> <p class="text-h6 font-weight-medium">Select Review Result</p>  </v-col>
                   

        <v-select v-if="activet2 && questionairetoview.t2id == null"
          v-model="t2action"
          :items="t2actions"
          item-text="item"
          item-value="item"
          label="Select"
          return-object
          single-line
        ></v-select>

            <v-text-field v-if="activet2 && questionairetoview.t2id == null  && t2action == 'Other'"
            v-model="t2otheraction"
            label="Describe Status"
          ></v-text-field>


                    <v-btn :loading="buttonloader"  @click="submitreview1(questionairetoview)" v-if="activet2 && questionairetoview.t2id == null" class="mb-15"
                     :disabled="t2action == '' || buttonloader == true">
                        SUBMIT FOLLOW-UP
                    </v-btn>
           </div>




                        </v-card-text>
                      
                       
                    </v-card-actions>
                       <p class="d-flex justify-center">__________________________________________________</p>
                </v-form>  <v-col cols=1> <v-btn @click="closereview" class="mb-15">
                        Close
                    </v-btn></v-col>
            </v-card>
            
            
                 
        </v-dialog>
    
         <v-snackbar
                centered
                style="z-index: 9999"
                color="green"
                v-model="successSB"
                :timeout=2000
                > Change Successfully Submitted
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="successSB = false"
                    >
                    x
                    </v-btn>
                </template>
    </v-snackbar> 


            </div>                                                            <!-- SNACKBARS  -->
        <v-snackbar
                centered
                style="z-index: 9999"
                v-model="invalidSB"
                :timeout=2000
                > An Error Occured
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="invalidSB = false"
                    >
                    Close
                    </v-btn>
                </template>
    </v-snackbar>




    </div>
</template>


<script>


import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import axios from 'axios'
import navbar from './../components/navbar'

export default {
    name:'adminhome',
    components: {'navbar':navbar},
    mounted: function(){


            //set before noon
            //set before evening
            //before today is false

            var noon = new Date()
            noon = noon.setHours(12,0,0)
            var now = new Date()
            if (now > noon){this.noon = true}
            else{this.noon = false}
            console.log(`noon = ${this.noon}`)
            var evening = new Date
            evening = evening.setHours(20,0,0)
            if (now > evening){this.evening = true;}
            else {this.evening = false}
            console.log(`evening = ${this.evening}`)

            let timezonestr = new Date().toLocaleString('en-US',{timezone:"America/New_York"})
            let timezonedate = new Date((timezonestr.slice(0,10))).toISOString().slice(0, 10)
            this.date = timezonedate
            this.chosenhouse = this.currentuser.house

            
            axios.get('/questionaire/day/'+this.date,{headers: {"Authorization": "Bearer "+this.token}}).then( (resp) =>{
                for(let i=0;i<resp.data.length;i++){
                    this.dailyquestionaires.push(resp.data[i])}
                for(let i=0;i<this.dailyquestionaires.length;i++) {
                    axios.get('/questionaire/'+this.dailyquestionaires[i].questionaireid+'/questions').then( (resp) => {
                        for(let i=0;i<resp.data.length;i++){
                            this.dailyquestions.push(resp.data[i])}      
                     })
                }for(let i=0; i<this.users.length;i++) {
                if ((this.users[i].Role == ('resident'))||(this.users[i].Role == ('Resident')))
                {this.tableusers.push(this.users[i])}}

                 for (let i=0;i<this.tableusers.length;i++){
                    this.tableusers[i].completions=[]
                    for (let j=0;j<this.dailyquestionaires.length;j++){
                        if(this.dailyquestionaires[j].userid == this.tableusers[i].userid){
                            this.tableusers[i].completions.push(this.dailyquestionaires[j])}
                        else{continue}
                        }
                    }console.log(this.tableusers)


            })






               
              
   
    }, 
    
watch: {date (newVal) {
    console.log(`changing date to ${newVal}`)
    this.dailyquestionaires=[];
    this.dailyquestions=[];
    this.tableusers=[];
    var today = new Date().setHours(0,0,0,0) 
    var dateint = new Date(`${newVal} 00:00:00 GMT-4:00`).setHours(0,0,0,0)
    console.log(`today is ${today}`)
    console.log(`going to ${dateint}`)
    if(today > dateint){this.beforetoday=true}
    else {this.beforetoday=false;

            var noon = new Date()
            noon = noon.setHours(12,0,0)
            var now = new Date()
            if (now > noon){this.noon = true}
            else{this.noon = false}
            console.log(`noon = ${this.noon}`)
            var evening = new Date
            evening = evening.setHours(20,0,0)
            if (now > evening){this.evening = true}
            else {this.evening = false}
            console.log(`evening = ${this.evening}`)
}
    console.log(typeof(this.date))
    var newdate = Date.now()
    console.log(newdate)
    console.log(dateint < newdate)

axios.get('/questionaire/day/'+newVal,{headers: {"Authorization": "Bearer "+this.token}}).then( (resp) =>{
    

for(let i=0;i<resp.data.length;i++){
                    this.dailyquestionaires.push(resp.data[i])}
for(let i=0;i<this.dailyquestionaires.length;i++) {
                    axios.get('/questionaire/'+this.dailyquestionaires[i].questionaireid+'/questions').then( (resp) => {
                        for(let i=0;i<resp.data.length;i++){
                            this.dailyquestions.push(resp.data[i])}      
                     })
                }

                 for(let i=0; i<this.users.length;i++) {
                if ((this.users[i].Role == ('resident'))||(this.users[i].Role == ('Resident')))
                {this.tableusers.push(this.users[i])}}

                 for (let i=0;i<this.tableusers.length;i++){
                    this.tableusers[i].completions=[]
                    for (let j=0;j<this.dailyquestionaires.length;j++){
                        if(this.dailyquestionaires[j].userid == this.tableusers[i].userid){
                            this.tableusers[i].completions.push(this.dailyquestionaires[j])}
                        else{continue}
                        }
                    }console.log(this.tableusers)


})}


},

    data: function () {
        return {
            tableusers:[],
            dailyquestionaires:[],
            questionairetoview:{},
            questionstoreview:[],
            awaitingt1:false,
            respondent:'',
            init:'',
            activet1:false,
            buttonloader:false,
            chosenhouse:'',
            houses:['Broadview Village','House 3','Oriole Parkway','Ridley','House 1','House 2','Coach House','Meldazy','Hancock','Spruce Hill',"L'Amoreaux","Townhouse"],
            successSB:false,
            awaitingt2:false,
            activet2:false,
            t1action:'',
            t1otheraction:'',
            notifyt2:[],
            noon:false,
            evening:false,
            beforetoday:false,
            t2action:'',
            t2otheraction:'',
            actions:['Symptoms Have Changed','Data Entry Error','Has Appointment for Symptom','RN Contacted','Resident Quarantined','Other'],
            t2actions:['More information required','Taken to Walk-in clinic','Set appointment with PCG','Called EMS','Resident Quarantined','Other'],
            details:false,
            dailyquestions:[],
            menu2:false,
            invalidSB:false,
            date:new Date((new Date().toLocaleString('en-US',{timezone:"America/New_York"}).slice(0,10))).toISOString().slice(0, 10),
            headers: [{
              text:"Name",
              align:"start",
              sortable: false,
              value:"Name"},
              {text:"Survey 1",value:"completions[0]"},
              ]
        }
        },
    methods: {
      ...mapActions({

        logout: 'logout',

      }),

      submitlogout () {
          try{this.logout().then(() => {

          if(this.authenticated == false){
          this.$router.replace({
            name: 'login'
          });console.log('logged out')} else {console.log('auth boolean '+this.authenticated)
              console.log('logout failed')}

          })}catch (e) {console.log('logout failed outside')}
    
      },
      logdate () {console.log(this.date)},

      opendetails(item){
          this.questionairetoview = Object.assign({},item);
          this.details=true;
          console.log(this.questionairetoview)
          for (let i=0;i<this.dailyquestions.length;i++){
              if(this.dailyquestions[i].questionaireid == item.questionaireid ){this.questionstoreview.push(this.dailyquestions[i])}
              for(let i=0;i<this.tableusers.length;i++){
                  if(this.tableusers[i].userid == this.questionairetoview.userid){this.respondent=this.tableusers[i].Name}
              }
          }
          if(this.questionairetoview.flagged == 'true'){this.awaitingt1=true}
          if(this.questionairetoview.flagged == 'true' && this.questionairetoview.t1id != null){this.awaitingt1=false}
          if(this.questionairetoview.t1id != null){this.awaitingt2=true}
          if(this.questionairetoview.t1id != null && this.questionairetoview.t2id != null){this.awaitingt2=false}
          if(this.currentuser.Role == 'T1'){this.activet1=true}
          if(this.currentuser.Role == 'T2'){this.activet2=true}

      },

      closereview(){
          this.details=false;
          this.questionstoreview=[]
          this.awaitingt1=false
          this.activet1=false
          this.awaitingt2=false
          this.activet2=false
          this.t1action=''
          this.t1otheraction=''
          this.t2action=''
          this.t2otheraction=''
      },

      submitreview1 (){

            console.log(this.currentuser)
          this.buttonloader=true
          var obj = {}
          if (this.currentuser.Role == "T1"){obj={"t1":this.currentuser.Name,"verification":"","questionaireid":this.questionairetoview.questionaireid}
          if (this.t1action == 'Other'){obj.verification = this.t1otheraction}
          else{obj.verification = this.t1action}
          obj["escalated"]=this.notifyt2[0]
          axios.post('/questionaire/t1',obj,{headers: {"Authorization": "Bearer "+this.token}}).then( () => {
              ''
          }  ).catch(()=> {this.buttonloader=false;this.invalidSB=true} )
          
          
          }



          else {obj={"t2":this.currentuser.Name,"verification":"","questionaireid":this.questionairetoview.questionaireid}
          if (this.t2action == 'Other'){obj.verification = this.t2otheraction}
          else{obj.verification = this.t2action}
          axios.post('/questionaire/t2',obj,{headers: {"Authorization": "Bearer "+this.token}}).then( () => {
              ''
          }  ).catch(()=> {this.buttonloader=false;this.invalidSB=true} )
          
          
          }
          //set the data
          
         
         //send the update
          

          
         //update the data, reset then re-open to same questionaire
        setTimeout( ()=> {

            this.details=false,
            this.tableusers=[],
            this.dailyquestionaires=[],
            this.questionairetoview={},
            this.questionstoreview=[],
            this.dailyquestions=[],

             axios.get('/questionaire/day/'+this.date,{headers: {"Authorization": "Bearer "+this.token}}).then( (resp) =>{
                for(let i=0;i<resp.data.length;i++){
                    this.dailyquestionaires.push(resp.data[i])}
                for(let i=0;i<this.dailyquestionaires.length;i++) {
                    axios.get('/questionaire/'+this.dailyquestionaires[i].questionaireid+'/questions').then( (resp) => {
                        for(let i=0;i<resp.data.length;i++){
                            this.dailyquestions.push(resp.data[i])}      
                     })
                }for(let i=0; i<this.users.length;i++) {
                if ((this.users[i].Role == ('resident'))||(this.users[i].Role == ('Resident')))
                {this.tableusers.push(this.users[i])}}

                 for (let i=0;i<this.tableusers.length;i++){
                    this.tableusers[i].completions=[]
                    for (let j=0;j<this.dailyquestionaires.length;j++){
                        if(this.dailyquestionaires[j].userid == this.tableusers[i].userid){
                            this.tableusers[i].completions.push(this.dailyquestionaires[j])}
                        else{continue}
                        }
                    }console.log(this.tableusers)


            })






               
                ;this.buttonloader=false;this.successSB=true
                  },1000  )
                    
                     

            

          
      },
    },
        
    computed:{
        ...mapGetters({
            authenticated: 'authenticated',
            token: 'token',
            user: 'user',
            users: 'users'
        }),

        currentuser(){
            var current={};
            for(let i=0;i<this.users.length;i++){
                if (this.users[i].userid == this.user){current = this.users[i]}
                else{continue}
            }return current
        },

        t1reviewer(){

            if(this.questionairetoview.t1id == this.currentuser.Name){return 'YOU'}
            else{return this.questionairetoview.t1id}
        },
        t2reviewer(){

            if(this.questionairetoview.t2id == this.currentuser.Name){return 'YOU'}
            else{return this.questionairetoview.t2id}
        },

/*         timezonestr(){

            
            let timezonestr = new Date().toLocaleString('en-US',{timezone:"America/New_York"})
            let timezonedate = new Date((timezonestr.slice(0,10))).toISOString().slice(0, 10)
            return timezonestr

        }, */


        maxdate() {
          return new Date().toISOString().slice(0, 10)
      }

    }

    }





</script>
