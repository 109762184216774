import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login'
import adminhome from '../views/adminhome'
import residenthome from '../views/residenthome'
import users from '../views/users'
import groups from '../views/groups'
import setup from '../views/setupnew'
import store from '../store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },

  {path: '/adminhome',
  name: 'adminhome',
  component: adminhome,
  meta: {requiresAuth: true,
          requiresAdmin:true}},

  {path: '/users',
  name: 'users',
  component: users,
  meta: {requiresAuth: true,
    requiresAdmin:true}},

  {path: '/setup',
  name: 'setup',
  component: setup,
  meta: {requiresAuth: true,
    requiresAdmin:true}},

  {path: '/residenthome',
  name: 'residenthome',
  component: residenthome,
  meta: {requiresAuth: true}},

  {path: '/groups',
  name: 'groups',
  component: groups,
  meta: {requiresAuth: true}},





  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  }



]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  if (to.path != ('/')) {
  store.dispatch('checkifadmin').then( () => {if (             ((to.matched.some(record => record.meta.requiresAuth)) && (store.getters.authenticated == false))
                                                  ||((to.matched.some(record => record.meta.requiresAdmin)) && (store.getters.admin == false)) ) 
    {router.replace('/')} 
   else
   {next()}})
 }
   else{next()}})

export default router
