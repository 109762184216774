<template>
  <v-container class="d-flex justify-center" app>          
            <v-card elevation="2">  <!-- TOP NAV BAR  -->
           <div>  
               <div class="d-flex justify-center"  v-if="users.length > 0">
                    <img contain width="15%" class="mr-10 ml-5" src="../../public/tsalogo.svg">
                  <span class="ml-n7 text-h4 mt-5">{{currentuser.Name}}</span></div>

               </div>
                
                <div class="text-center" >
                                <v-btn to="/adminhome" text class="mr-10 ml-2">HOME</v-btn>
                            
                         
                                <v-btn to="/users" text class="mr-10">USERS</v-btn>
                          

                                <!--  <v-menu  offset-y>
                             <template v-slot:activator="{ on, attrs }"> -->
                                        <v-btn class="mr-10"
                                        text
                                        to="/setup"
                                        >
                                        SCREENER
                                        </v-btn>
                                  <!--   </template>
                                    <v-list>
                                        <v-list-item>
                           <router-link style="text-decoration:none" class="black--text" to="/setup"> <v-list-item-title  >Surveys</v-list-item-title> </router-link>

                            </v-list-item>
                                        <v-list-item>
                           <router-link style="text-decoration:none" class="black--text" to="/groups"> <v-list-item-title >Groups</v-list-item-title> </router-link>

                            </v-list-item>

                        </v-list>
                        </v-menu> -->

                                <v-btn v-on:click="submitlogout" text fab><v-icon>mdi-logout</v-icon></v-btn>


</div>
            </v-card>
            </v-container>
    
</template>

<script>

import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'


export default {
    name: 'navbar',
    components: {},
    mounted: function (){
        this.$store.dispatch('getusers')
    },
    data: function () {
        return {
           
            

        }
    },
    methods:{

        ...mapActions({
        logout: 'logout',
        }),

          submitlogout () {
          try{this.logout().then(() => {

          if(this.authenticated == false){
          this.$router.replace({
            name: 'login'
          });console.log('logged out')} else {console.log('auth boolean '+this.authenticated)
              console.log('logout failed')}

          })}catch (e) {console.log('logout failed outside')}
    
      },
    
    
    },


    computed:{
        ...mapGetters({
            users: 'users',
            user: 'user',
            authenticated: 'authenticated'
            }),

          currentuser(){
            var current={};
            for(let i=0;i<this.users.length;i++){
                if (this.users[i].userid == this.user){current = this.users[i]}
                else{continue}
            }return current
        },

    }


}
</script>
