<template>
    <v-container>
        <navbar></navbar>
            <div class="text-center">  
                 <v-progress-circular class="mt-15" :size="200" v-if="loading"
      indeterminate
      color="green"
    ></v-progress-circular>

    <div v-if="!loading" class="text-h6 mb-5 mt-5">Screener Details</div>


     <v-data-table v-if="!loading" class="mb-10"
                            hide-default-footer
                            :items-per-page="11"
                            :items="hardcodedscreener"
                            :headers="qheaders">

                                <template v-slot:item.flaganswer="{ item }">
 
                                    <span class="red--text" v-if="((item.flaganswer == ('yes'))
                                                                    ||(item.flaganswer == ('Yes'))
                                                                    ||(item.flaganswer == ('no'))
                                                                    ||(item.flaganswer == ('No'))
                                                                  )">
                                                            {{item.flaganswer}}</span>
                                     <span v-else-if="item.flaganswer != null"> {{item.flaganswer}} </span>                        
                                    <span v-else>No Flag </span>
                                    
                                </template>

                                <template v-slot:item.groupid="{ item }">
 

                                    <span v-if="((item.groupid == undefined )
                                                ||(item.groupid == null)
                                                ||(item.groupid == '')
                                                ||(item.groupid =='undefined')
                                                ||(item.groupid =='none'))" >N/A </span>

                                    <span v-else>{{item.groupid}}</span>
                                    
                                    
                                </template>

                               <template v-slot:item.actions="{ item }">

                                   <div class="text-right">       
                                       <v-icon        
                                            class="mr-6 mt-4 mb-4"
                                            @click="openeditquestionaire(item)"
                                            >
                                            mdi-pencil
                                        </v-icon>

                                        <v-icon    
                                            class="mr-6 mt-4 mb-4"
                                            @click="opendeletequestion(item)"
                                            >
                                            mdi-delete
                                        </v-icon>
                                    </div>
                             </template>
                                </v-data-table>


<!-- 
                <v-data-table v-if="!loading"
                :headers="headers"
                show-expand
                :items="schemalist"
                :expanded.sync="expanded"
                :single-expand="true"
                item-key="name"
                :search="search"
                :items-per-page="20"
                class="elevation-1">
                    <template v-slot:item.name="{ item }">
                        <span class="text-h4">
                            {{ item.name }}     
                        </span>

                    <span class="font-weight-black green--text" v-if="item.name == schemas.active">ACTIVE</span>
                    
                    </template>

                    <template v-slot:expanded-item="{ item }">
                        <div class="text-right">       
                            <v-icon   
                            large
                            class="mr-6 mt-4 mb-4"
                            @click="openrenamequestionaire(item)"
                            >
                                mdi-pencil
                            </v-icon>

                            <v-icon
                            large
                            class="mr-6 mt-4 mb-4"
                            @click="opendeletequestionaire(item)"
                            >
                            mdi-delete
                            </v-icon></div>
                         
                            <v-data-table class="mb-10"
                            hide-default-footer
                            :items-per-page="20"
                            :items="item.questions"
                            :headers="qheaders">

                                <template v-slot:item.flaganswer="{ item }">
 
                                    <span class="red--text" v-if="((item.flaganswer == ('yes'))
                                                                    ||(item.flaganswer == ('Yes'))
                                                                    ||(item.flaganswer == ('no'))
                                                                    ||(item.flaganswer == ('No'))
                                                                  )">
                                                            {{item.flaganswer}}</span>
                                    <span v-else-if="item.flaganswer != null"> {{item.flaganswer}} </span>                        
                                    <span v-else>No Flag </span>
                                    
                                </template>

                                <template v-slot:item.groupid="{ item }">
 

                                    <span v-if="((item.groupid == undefined )
                                                ||(item.groupid == null)
                                                ||(item.groupid == '')
                                                ||(item.groupid =='undefined')
                                                ||(item.groupid =='none'))" >N/A </span>

                                    <span v-else>{{item.groupid}}</span>
                                    
                                    
                                </template>

                               <template v-slot:item.actions="{ item }">

                                   <div class="text-right">       
                                       <v-icon        
                                            class="mr-6 mt-4 mb-4"
                                            @click="openeditquestionaire(item)"
                                            >
                                            mdi-pencil
                                        </v-icon>

                                        <v-icon    
                                            class="mr-6 mt-4 mb-4"
                                            @click="opendeletequestion(item)"
                                            >
                                            mdi-delete
                                        </v-icon>
                                    </div>
                             </template>
                                </v-data-table>

                                <div class="text-left mb-10 ml-2"> 
                                    <v-btn @click='openaddquestion(item)'>
                                    Add to survey
                                    </v-btn>
                               
                                
                                    <v-btn @click="openactivatequestionaire(item)" class="ml-3 green lighten-3" v-if="item.name != schemas.active">
                                    Activate Survey
                                    </v-btn>
                                </div>
      
                           
                            </template>

                            </v-data-table>
                         <div v-if="!loading" class="text-left mt-5 ml-2"> 
                                    <v-btn @click="opencreatesurvey">
                                    Create New Survey
                                    </v-btn>
                                </div>
       -->

      
      
      
      
      </div>  


      <!--*************************************************ADD NEW Questionaire******************************************** -->

  <v-dialog
                v-model="newquestionaireform"
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Add New Survey</span>
                        </v-card-title>
                        

                        <v-card-text>
                        <v-container>   
                           <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="newquestionairename"
                                    :rules="formRules"
                                    label="Name of Questionaire"
                                    required
                                    hint="must be as least 6 characters"
                                    ></v-text-field>
                                </v-col>

                         <v-col col=12>    <v-list flat>
                                <v-list-item-group  v-for="(question,i) in newquestions"
                                :key="question"
                                :index="i">

                               <v-list-item  class="mt-n10">
                             <v-list-item-content class="mb-15">

                             <v-col cols=12><span class="text-h6">Question {{i+1}}</span>
                                <v-btn class="mb-2" v-if="newquestions.length>1" x-small @click="removefromnewq(i)"  text fab>
                                    <v-icon>mdi-trash-can-outline</v-icon></v-btn></v-col>
                                    
                                    
                                    <v-text-field
                                    v-model="newquestions[i].question"
                                    label="Enter Question"
                                     ></v-text-field>

                                     <v-checkbox
                                    v-model="newquestions[i].flagged"
                                    label="Flag Question?"
                                    >       
                                         </v-checkbox>


                                     <v-select
                                      v-model="newquestions[i].flag"
                                     v-if="newquestions[i].flagged"
                                    :items="options"
                                     label="Flag Answer?"
                                     ></v-select>

                                       <v-select v-if="newquestions[i].flagged"
                                       v-model="newquestions[i].alert"
                                        :items="Object.keys(groups)"
                                        label="Who will be notified?"
                                        ></v-select>

                                

                             </v-list-item-content>
                                
                                </v-list-item>
                                </v-list-item-group>
                                    <row>   <v-col cols=1><v-btn
                                            class="mx-2"
                                            fab
                                            small
                                            dark
                                            color="indigo lighten-2"
                                            @click="createnewquestion"
                                            >
                                            <v-icon dark>
                                                mdi-plus
                                            </v-icon>
                                            </v-btn></v-col></row>
                            </v-list></v-col>



                          
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            :loading="bloading"
                            :disabled="bloading"
                            text
                            x-large
                            @click="addnewquestionaire"
                        >
                            Add Survey
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            x-large
                            @click="closenewquestionaireform"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>



<!--   *************************************************DELETE SURVEY ********************************************************************************8            -->


 <v-dialog
                v-model="deletequestionaireform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Delete Survey?</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                               
                               <p>Are you sure you want to delete survey {{ questionairetodelete }}?
                                   All previous completions will still exist</p>    
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            @click="deletequestionaire"
                        >
                            Delete Survey
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="deletequestionaireform = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        


<!--   *************************************************EDIT SURVEY NAME********************************************************************************        -->


 <v-dialog
                v-model="editqnameform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Rename Survey</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                               
                               <p>Enter new name for {{ questionairetorename }}:
                                   </p>    
                            </v-row>

                         <v-row>
                               
                             <v-col cols=8>  <v-text-field v-model="newqname"></v-text-field> </v-col>

                            </v-row>


                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            @click="renamequestionaire"
                        >
                            Rename Survey
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="editqnameform = false;newqname = ''"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        


<!--   *************************************************EDIT Question********************************************************************************        -->

<v-dialog
                v-model="editquestionaireform"
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Edit Survey Question</span>
                        </v-card-title>
                        

                        <v-card-text>
                        <v-container>   
                           <v-row>
                                <v-col cols="12">
                            <p class="text-h5 font-weight-medium"> </p>
                                </v-col>

                         <v-col col=12>    <v-list flat>
                                <v-list-item-group>

                               <v-list-item  class="mt-n10">
                             <v-list-item-content class="mb-15">

                             <v-col cols=12><span class="text-h6">Update Question Details:</span>
                                <v-btn class="mb-2" v-if="newquestions.length>1" x-small @click="removefromnewq(i)"  text fab>
                                    <v-icon>mdi-trash-can-outline</v-icon></v-btn></v-col>
                                    
                                    
                                    <v-text-field
                                    v-model="questiontoedit.question"
                                    label="Enter Question"
                                     ></v-text-field>

                                     <v-checkbox
                                     v-if="questiontoedit.flaganswer != null"
                                    v-model="editflag"
                                    label="Flag Question?"
                                    >       
                                         </v-checkbox>

                                        <v-select

                                      :v-model="questiontoedit.flaganswer"
                                      :value="questiontoedit.flaganswer"
                                     v-if="editflag"
                                    :items="options"
                                     label="Flag Answer?"
                                     ></v-select>

                                       <v-select v-if="editflag"
                                       :v-model="questiontoedit.groupid"
                                        :items="Object.keys(groups)"
                                        label="Who will be notified?"
                                        ></v-select>

                                

                             </v-list-item-content>
                                
                                </v-list-item>
                                </v-list-item-group>
                            </v-list></v-col>



                          
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                         :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            x-large
                            @click="editquestion"
                        >
                            Edit Question
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            x-large
                            @click="editquestionaireform=false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog> 


<!--    ************************************************************************ Surveys: DELETE A QUESTION ******************************************* -->



   <v-dialog
                v-model="deletequestionform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Delete Question?</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                               
                               <p>Remove question "{{questiontodelete.question}}" from survey {{ questiontodelete.qschema }}?</p>    
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                         :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            @click="deletequestion"
                        >
                            Delete Question
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="deletequestionform = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        


         <!--    ************************************ Surveys ADD A QUESTION ******************************************* -->

      
       <v-dialog
                v-model="addtoquestionaireform"
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Add to {{questionairetoaddto.name}}</span>
                        </v-card-title>
                        

                        <v-card-text>
                        <v-container>   
                           <v-row>
                                <v-col cols="12">
                            <p class="text-h5 font-weight-medium"> </p>
                                </v-col>

                         <v-col col=12>    <v-list flat>
                                <v-list-item-group  v-for="(question,i) in newquestions"
                                :key="question"
                                :index="i">

                               <v-list-item  class="mt-n10">
                             <v-list-item-content class="mb-15">

                             <v-col cols=12><span class="text-h6">New Question</span>
                                <v-btn class="mb-2" v-if="newquestions.length>1" x-small @click="removefromnewq(i)"  text fab>
                                    <v-icon>mdi-trash-can-outline</v-icon></v-btn></v-col>
                                    
                                    
                                    <v-text-field
                                    v-model="newquestions[i].question"
                                    label="Enter Question"
                                     ></v-text-field>

                                     <v-checkbox
                                    v-model="newquestions[i].flagged"
                                    label="Flag Question?"
                                    >       
                                         </v-checkbox>

                               
                                        <v-select
                                      v-model="newquestions[i].flag"
                                     v-if="newquestions[i].flagged"
                                    :items="options"
                                     label="Flag Answer?"
                                     ></v-select>

                                       <v-select v-if="newquestions[i].flagged"
                                       v-model="newquestions[i].alert"
                                        :items="Object.keys(groups)"
                                        label="Who will be notified?"
                                        ></v-select>

                                

                             </v-list-item-content>
                                
                                </v-list-item>
                                </v-list-item-group>
                                    <row>   <v-col cols=1><v-btn
                                            class="mx-2"
                                            fab
                                            small
                                            dark
                                            color="indigo lighten-2"
                                            @click="createnewquestion"
                                            >
                                            <v-icon dark>
                                                mdi-plus
                                            </v-icon>
                                            </v-btn></v-col></row>
                            </v-list></v-col>



                          
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            x-large
                            @click="addtoquestionaire"
                        >
                            Add to Survey
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            x-large
                            @click="closenewquestionaireform"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>      

        
<!--    ************************************ Surveys TAB: ACTIVATE A QUESTIONAIRE ******************************************* -->


   <v-dialog
                v-model="activatequestionaireform"
                persistent
                max-width="600px">
                <v-card>
                    <v-form ref='form'>
                        <v-card-title>
                            <span class="headline">Activate Questionaire</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>   
                           <v-row>
                               
                               <p>Activate survey {{ questionairetoactivate}}?</p>    
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        :loading="bloading"
                            :disabled="bloading"
                            color="blue darken-1"
                            text
                            @click="activatequestionaire"
                        >
                            Activate
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="activatequestionaireform = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>        



 <v-snackbar
                centered
                style="z-index: 9999"
                v-model="invalidSB"
                :timeout=2000
                > An Error Occured
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="invalidSB = false"
                    >
                    X
                    </v-btn>
                </template>
    </v-snackbar>


            <v-snackbar
                centered
                style="z-index: 9999"
                color="green"
                v-model="successSB"
                :timeout=2000
                > Change Successfully Submitted
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="successSB = false"
                    >
                    x
                    </v-btn>
                </template>
    </v-snackbar> 


    </v-container>
</template>



<script>
import navbar from './../components/navbar'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
    name: 'setup',
    components: {'navbar':navbar},
    mounted: function(){
        axios.get('/questionaire/schemas', {headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => 
                { 
                this.schemas=resp.data;
                this.hardcodedscreener = this.schemas["New"]
                console.log(this.hardcodedscreener)
                this.loading=false
/* 
                var keys = Object.keys(this.schemas)
                for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}

                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                }console.log(this.schemalist) */
                 
                },
                //console.log(this.hardcodedscreener),
                
                //setTimeout(() => {this.loading=false},500)
                )

    },
    data: function () {
        return {
            schemas:{covid1:[{qschema:''}]},
            schemalist:[],
            successSB:false,
            editflag:false,
            editqnameform:false,
            deletequestionform:false,
            questionairetoaddto:{name:''},
            questionairetoactivate:{},
            addtoquestionaireform:false,
            activatequestionaireform:false,
            questiontodelete:{},
            groups:[],
            hardcodedscreener:{"name":"","questions":[]},
            editquestionaireform:false,
            newqname:'',
            deletequestionaireform:false,
            questionairetodelete:{},
            questionairetorename:'',
            invalidSB:false,
            newquestionaireform:false,
            options:['Yes','No'],
            newquestionairename:'',
            questiontoedit:{flagged:false},
            newquestions:[{"question":"","flag":"","alert":"",flagged:false}],
            loading:true,
            bloading:false,
            expanded:[],
             headers:[
                     {text:'Survey',value:'name'},
                      {text: 'Expand',value: 'data-table-expand' },
                    ],
            qheaders:[
                     {text:'Questions',value:'question', sortable:false},
                     {text:'Flagged Answer',value:'flaganswer',sortable:false},
                     //{text:'Contact on Flag',value:'groupid',sortable:false},
                     //{text:'Actions',value:'actions',sortable:false},

                    ],
            search:'',
             formRules: [
            v => !!v || 'Field is required',
            v => v.length > 4 || 'Must be at least 5 characters'],

        }
        },

    methods: {

         closenewquestionaireform(){
          this.newquestionaireform=false
          this.addtoquestionaireform=false
          this.$refs.form.reset()
          this.newquestionairename = ''
          this.newquestions=[{"question":"","flag":"","alert":"",flagged:false}]
      },
        opencreatesurvey(){
             axios.get('/users/email', {headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => {
                 this.groups=resp.data;
                 this.newquestionaireform=true
             })
        },
        openaddquestion(item){
            this.questionairetoaddto =item
            console.log(this.questionairetoaddto)
             axios.get('/users/email', {headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => {
                 this.groups=resp.data;
                 this.addtoquestionaireform=true
             })

        },

        createnewquestion(){
            var question= {"question":"","flagged":false,"flag":"","alert":""}
            this.newquestions.push(question)
        },
        removefromnewq(i){
            this.newquestions.splice(i,1)
      },
       openeditquestionaire(item){
           axios.get('/users/email', {headers: {"Authorization": "Bearer "+this.token}}).then( (resp) => {
            this.groups=resp.data;
            this.questiontoedit = Object.assign({},item)
            console.log(item)
            if((this.questiontoedit.flaganswer=='undefined')||(this.questiontoedit.flaganswer=='')){this.editflag = false}
            else{this.editflag = true}
            this.editquestionaireform=true })
        },
       addnewquestionaire(){
           this.bloading=true

          for (let i=0;i<this.newquestions.length;i++) 
          {var obj={"question":this.newquestions[i].question,"flaganswer":this.newquestions[i].flag,
           "groupid":this.newquestions[i].alert,"qschema":this.newquestionairename,
            "active":"false"};console.log(obj)
            axios.post('/questionaire',{obj},{headers: {"Authorization": "Bearer "+this.token}})}
            setTimeout(()=>{
                axios.get('/questionaire/schemas', {headers: {"Authorization": "Bearer "+this.token}}).then((resp)=>
                    {
                    this.schemas=resp.data;
                    this.successSB=true;

                     var keys = Object.keys(this.schemas)
                      for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}
                this.schemalist=[]
                
                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                    }console.log(this.schemalist);

                    this.closenewquestionaireform();this.bloading=false
                    }).catch( ()=> {this.invalidSB=true;this.bloading=false} )},
                500)


    },

      openactivatequestionaire(item){
            this.questionairetoactivate = item.name

            this.activatequestionaireform = true
        },


       opendeletequestionaire(item) {
            this.questionairetodelete = Object.assign({},item) 
            var key= Object.keys(item)[0]
            this.questionairetodelete = this.questionairetodelete[key]
            this.deletequestionaireform = true
            console.log(this.questionairetodelete)
        },
        opendeletequestion(item){
            this.questiontodelete = item
            this.deletequestionform = true

        },
        openrenamequestionaire(item) {
            this.questionairetorename = Object.assign({},item) 
            var key= Object.keys(item)[0]
            this.questionairetorename = this.questionairetorename[key]
            this.editqnameform = true
            this.newqname = this.questionairetorename
            console.log(this.questionairetorename)
        },

        renamequestionaire(){
            this.bloading=true
            axios.patch('/questionaire/name',{qschema:this.questionairetorename,new:this.newqname},
            {headers:{
                "Authorization": "Bearer "+this.token}}).then(

                    () => {  setTimeout(()=>{
                axios.get('/questionaire/schemas', {headers: {"Authorization": "Bearer "+this.token}}).then((resp)=>
                    {
                    this.schemas=resp.data;
                    this.successSB=true;

                     var keys = Object.keys(this.schemas)
                      for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}
                this.schemalist=[]
                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                    }console.log(this.schemalist);

                    this.editqnameform=false;this.newqname='';this.bloading=false
                    }).catch( ()=> {this.invalidSB=true;this.bloading=false} )},
                500)
}

                   
                ).catch( ()=> {this.invalidSB=true;this.bloading=false} )
        },

      deletequestionaire() {

            this.bloading=true
               axios.delete('/questionaire',{data:{"qschema":this.questionairetodelete},
            headers: {
                "Authorization": "Bearer "+this.token}}).then( ()=>{

                     setTimeout(()=>{
                         axios.get('/questionaire/schemas', {
                        headers: {
                         "Authorization": "Bearer "+this.token}}).then(
                          (resp)=>{
                        this.schemas=resp.data;
                        var keys = Object.keys(this.schemas)
                         for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}
                this.schemalist=[]
                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                    }
                        this.successSB=true;
                        this.deletequestionaireform=false
                        this.bloading=false
                        
                        })
                        
                    },700)



                }  ).catch( ()=>{this.invalidSB=true;this.bloading=false})



        },

           deletequestion(){
                this.bloading=true
            axios.delete('/questionaire/question',{data:{"id":this.questiontodelete.id},
            headers: {
                "Authorization": "Bearer "+this.token}}).then( ()=>{
                        
                        setTimeout(()=>{
                         axios.get('/questionaire/schemas', {
                        headers: {
                         "Authorization": "Bearer "+this.token}}).then(
                          (resp)=>{
                        this.schemas=resp.data;
                        var keys = Object.keys(this.schemas)
                         for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}
                this.schemalist=[]
                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                    }
                        this.successSB=true;
                        this.deletequestionform=false
                        this.bloading=false
                        
                        })
                        
                    },400)



                }  ).catch( ()=>{this.invalidSB=true;this.bloading=false})

        },

         editquestion() {
             this.bloading=true
            if(!this.editflag){this.questiontoedit.flaganswer='';this.questiontoedit.groupid=''}
            else{''}
            axios.patch('/questionaire/question', this.questiontoedit, {headers: {
                "Authorization": "Bearer "+this.token}}).then( () => {
                    setTimeout(()=>{
                         axios.get('/questionaire/schemas', {
                        headers: {
                         "Authorization": "Bearer "+this.token}}).then(
                          (resp)=>{
                        this.schemas=resp.data;
                        var keys = Object.keys(this.schemas)
                         for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}
                this.schemalist=[]
                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                    }
                        this.successSB=true;
                        this.editquestionaireform=false
                        this.bloading=false
                        
                        })
                        
                    },400)

                }).catch( ()=>{this.invalidSB=true;this.bloading=false})


        },

         addtoquestionaire(){
             this.bloading=true
    
          for (let i=0;i<this.newquestions.length;i++) 
          {var obj={"question":this.newquestions[i].question,"flaganswer":this.newquestions[i].flag,
                    "groupid":this.newquestions[i].alert,"qschema":this.questionairetoaddto.name,"active":''};
            if(this.questionairetoaddto.name == this.schemas.active){obj.active='true'}
            else{obj.active='false'}
            console.log(obj)
              axios.post('/questionaire',{obj},{headers: {
                "Authorization": "Bearer "+this.token}}).catch( ()=> {this.invalidSB=true;this.bloading=false} )}

            setTimeout(()=>{
                         axios.get('/questionaire/schemas', {
                        headers: {
                         "Authorization": "Bearer "+this.token}}).then(
                          (resp)=>{
                        this.schemas=resp.data;
                         var keys = Object.keys(this.schemas)
                          for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}
                this.schemalist=[]
                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                    }

                        this.successSB=true;
                        this.closenewquestionaireform()
                        this.bloading=false
                        }).catch( ()=> {this.invalidSB=true;this.bloading=false} )
                        
                    },700)
            

        


      },

      
        activatequestionaire(){
            this.bloading=true
            axios.patch('/questionaire',{qschema:this.questionairetoactivate},{headers: {
                "Authorization": "Bearer "+this.token}}).then( () => {

                         setTimeout(()=>{
                         axios.get('/questionaire/schemas', {
                        headers: {
                         "Authorization": "Bearer "+this.token}}).then(
                          (resp)=>{
                        this.schemas=resp.data;
                         var keys = Object.keys(this.schemas)
                          for(let s=0;s<keys.length;s++)
                if(keys[s]=='active'){keys.splice(s,1)}
                else{''}
                this.schemalist=[]
                for(let x=0;x<keys.length;x++){
                    var obj = {};
                    obj.name = keys[x]
                    obj.questions = this.schemas[keys[x]] 
                    this.schemalist.push(obj)
                    }
                        this.successSB=true;
                        this.activatequestionaireform=false
                        this.bloading=false
                        })
                        
                    },600)

                } ).catch( ()=> {this.invalidSB=true;this.bloading=false} )
        },

        

        

    },
    computed:{
        ...mapGetters({
            authenticated: 'authenticated',
            admin: 'admin',
            token: 'token'
            })
    }

}


</script>