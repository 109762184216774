import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import axios from 'axios'


//process.env.NODE_TLS_REJECT_UNAUTHORIZED

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuth:false,
    token:'',
    user:null,
    isAdmin:false,
    users:null,
    },

  getters: {
      authenticated(state){return state.isAuth},
      token(state){return state.token},
      user(state){return state.user},
      users(state){return state.users},
      admin(state){return state.isAdmin}
  },  
  mutations: {
    login(state,info){
        state.token=info.token;
        state.isAuth=true;
        state.user=info.userid;
        state.isAdmin=info.admin
},
    logout(state){
        state.token='';
        state.isAuth=false;
        state.user=null;
        state.isAdmin=false;

    },


    setusers(state,users){
      state.users=users
    },


},


actions:{
  async attemptLogin( { dispatch, state },creds){
      try{
          axios.post('/login', creds).then( (a)=> {
          console.log(a),
          console.log("fromstore "+state.token);
          return dispatch('loginaction', a.data)
          
          })
         }
          catch (e) {
            return false
          }
  },
  loginaction ( {commit}, data){
      commit('login',data);
      return true

  },
  async logout({ commit, state}){
      try{await axios.post('/logout',{},{
          headers: {
            "Authorization": "Bearer "+state.token}
        }).then( () => {commit('logout');
        console.log("should return ths before it fails..."+state.isAuth)
      return true} )
      }
      catch (e){if (e.response.status == 403){commit('logout');return true}
      else{return false}}
  },

   async checkifadmin ({ commit, state }) {
      try{
            await axios.get('/questionaire/testapi', {
            headers: {
                "Authorization": "Bearer "+state.token}});console.log('good');
            setTimeout( () => {return true},200 )
            
          } 
      catch (e) {
        if (e.response.status == 403){ console.log("caught unauth")
            commit('logout')
            return false} else{console.log('another error')}
           
        }
        },
        
        
    getusers ( {commit, state} ) {
      axios.get('/users',{headers: {"Authorization": "Bearer "+state.token}}).then(
        (resp) => {commit('setusers',resp.data);console.log(state.users);return true}
      ).catch( (e) => {console.log('failed to get users');console.log(e);return false})
         

    }


},
  modules: {
  },
  plugins: [createPersistedState()]
})
